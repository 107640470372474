import { render, staticRenderFns } from "./profile-view-job.vue?vue&type=template&id=b9bb11b6&scoped=true&"
import script from "./profile-view-job.vue?vue&type=script&lang=js&"
export * from "./profile-view-job.vue?vue&type=script&lang=js&"
import style0 from "./profile-view-job.vue?vue&type=style&index=0&id=b9bb11b6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9bb11b6",
  null
  
)

export default component.exports