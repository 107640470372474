import { authorizationsMap } from "@/const";

export default {
  convertType(type, status = "declined") {
    if (status === "accepted") {
      return authorizationsMap[type]?.approvedLabel || type;
    }
    return authorizationsMap[type]?.label || type;
  },
};
