<template>
  <div>
    <a-modal
      title="Change Password"
      width="500px"
      v-model="visiblePasswordModal"
    >
      <a-form-model
        layout="horizontal"
        ref="form"
        class="flex gap-2 flex-column"
        :model="form"
        :rules="rules"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item
          :colon="false"
          label-align="left"
          label="New Password"
          prop="password"
        >
          <a-input-password
            v-model="form.password"
            @input="removeSpaces('password')"
            placeholder="New password"
          />
        </a-form-model-item>
        <a-form-model-item
          :colon="false"
          label-align="left"
          label="Password Confirm"
          prop="re_password"
        >
          <a-input-password
            v-model="form.re_password"
            @input="removeSpaces('re_password')"
            placeholder="Confirm your password"
          />
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <div class="flex justify-content-between">
          <a-button @click="closePasswordModal"> Cancel </a-button>
          <a-button
            icon="save"
            type="primary"
            @click="changePassword"
            :loading="loading"
          >
            Save
          </a-button>
        </div>
      </template>
    </a-modal>

    <a-button icon="edit" type="primary" @click="openPasswordModal">
      Change Password
    </a-button>
  </div>
</template>

<script>
import SendModal from "@/components/send-modal.vue";
import { Button, FormModel, Input, Modal, notification } from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";

const { mapActions: profileActions, mapState: profileState } =
  createNamespacedHelpers("profile");

const initialForm = {
  password: "",
  re_password: "",
};

export default {
  components: {
    "a-button": Button,
    "a-modal": Modal,
    "a-form-model": FormModel,
    "a-form-model-item": FormModel.Item,
    "a-input-password": Input.Password,
    "send-modal": SendModal,
  },
  data() {
    return {
      visiblePasswordModal: false,
      loading: false,

      form: { ...initialForm },
      rules: {
        password: [
          {
            required: true,
            message: "Please enter your new password.",
            trigger: "change",
          },
        ],
        re_password: [
          {
            required: true,
            message: "Please confirm your new password.",
            trigger: "change",
          },
          {
            validator: (_, value, callback) => {
              if (!value) {
                callback("Please confirm your new password.");
              } else if (value !== this.form.password) {
                callback("Passwords do not match.");
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    ...profileState({
      associate: (state) => state.associate,
    }),
  },
  methods: {
    ...profileActions(["setTemporaryPassword"]),

    removeSpaces(fieldName) {
      if (typeof this.form[fieldName] === "string") {
        this.form[fieldName] = this.form[fieldName].replace(/\s+/g, "");
      }
    },
    closePasswordModal() {
      this.visiblePasswordModal = false;
      this.form = { ...initialForm };
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    openPasswordModal() {
      this.visiblePasswordModal = true;
    },

    changePassword() {
      if (!this.associate.user_id) {
        return;
      }

      this.$refs.form.validate(async (valid) => {
        if (!valid) return;

        this.loading = true;

        const payload = {
          user_id: this.associate.user_id,
          ...this.form,
        };

        try {
          await this.setTemporaryPassword(payload);

          this.showNotification(
            "success",
            "Success",
            "Password has been changed successfully."
          );
          this.closePasswordModal();
        } catch (error) {
          this.showNotification("error", "Error", error.message || error);
        } finally {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.ant-row {
  display: flex;
  margin: 0 !important;
}

.ant-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}
</style>
