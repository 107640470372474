<template>
  <div>
    <associate-activity-filters
      :current-filters="filters"
      :visible="filtersModalVisible"
      :key="filtersModalKey"
      @apply="applyFilters"
    />

    <associate-profile-view
      :visible.sync="visibleProfileModal"
      :associate_number.sync="associateNumberToShow"
      @edit="loadData"
    />

    <h2 class="view-header">Associate Activity</h2>
    <div class="flex align-items-center justify-content-end gap-2 mb-2">
      <a-button-group>
        <a-tooltip>
          <template slot="title"> Open Filters </template>
          <a-button
            icon="filter"
            :disabled="loading"
            @click="showFilterModal"
          />
        </a-tooltip>
        <a-tooltip>
          <template slot="title"> Reset Filters </template>
          <a-button
            icon="undo"
            :disabled="loading || disableResetButton"
            @click="resetFilters"
          />
        </a-tooltip>
      </a-button-group>
      <a-date-picker
        v-model="date"
        :disabled="loading"
        :format="visualDateFormat"
      />
    </div>
    <a-table
      size="small"
      row-key="id"
      :loading="loading"
      :columns="columns"
      :data-source="tableData"
      :customRow="
        (record) => ({
          on: {
            click: () =>
              openAssociateProfile(record.associate.associate_number),
          },
        })
      "
    >
      <span
        slot="associate"
        slot-scope="text, record"
        :key="record.associate.photo_url"
      >
        <grouped-associate-info
          :first-name="record.associate.first_name"
          :last-name="record.associate.last_name"
          :photo-url="record.associate.photo_url"
          :associate-number="record.associate.associate_number"
        />
      </span>
      <span slot="status" slot-scope="text, record">
        <a-tag :color="getTagColor(record.associate.status)">
          {{ convertStatusToText(record.associate.status) }}
        </a-tag>
      </span>
      <span slot="actions" slot-scope="text, record">
        <a-button
          icon="user"
          size="small"
          @click.stop="openAssociateProfile(record.associate.associate_number)"
        >
          Profile
        </a-button>
      </span>
    </a-table>
  </div>
</template>
<script>
import {
  Table,
  Input,
  Button,
  Tag,
  DatePicker,
  notification,
  Modal,
  Tooltip,
} from "ant-design-vue";

import { associateStatusColorsMap, associateStatusMap } from "@/const";
import api from "@/api";
import moment from "moment-timezone";
import AssociateActivityFilters from "@/components/associate-activity-filters.vue";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";
import ProfileAssociateView from "@/modules/profile/components/profile-view.vue";

const filtersBaseState = Object.freeze({
  associateStatus: undefined,
  role: undefined,
  associates: undefined,
});

export default {
  components: {
    "a-table": Table,
    "a-input": Input,
    "a-button": Button,
    "a-button-group": Button.Group,
    "a-tooltip": Tooltip,
    "a-tag": Tag,
    "a-date-picker": DatePicker,
    "a-modal": Modal,
    "associate-profile-view": ProfileAssociateView,
    "grouped-associate-info": GroupedAssociateInfo,
    "associate-activity-filters": AssociateActivityFilters,
  },
  mixins: [api],
  data() {
    return {
      tableData: [],
      columns: [
        {
          title: "Associate",
          key: "name",
          scopedSlots: { customRender: "associate" },
        },
        {
          title: "Status",
          key: "status",
          scopedSlots: { customRender: "status" },
          align: "center",
        },
        {
          title: "Job Classification",
          dataIndex: "associate.role.name",
          align: "center",
        },
        {
          title: "Payment Type",
          dataIndex: "associate.payment_type",
          align: "center",
        },
        {
          title: "Actions",
          key: "actions",
          scopedSlots: { customRender: "actions" },
          align: "center",
        },
      ],
      date: moment().startOf("day"),
      loading: false,
      filters: { ...filtersBaseState },
      associateNumberToShow: undefined,
      visibleProfileModal: false,
      filtersModalVisible: false,
      filtersModalKey: 0,
    };
  },
  watch: {
    date() {
      this.loadData();
    },
    filters: {
      handler(newVal, prevVal) {
        if (JSON.stringify(newVal) != JSON.stringify(prevVal)) {
          this.loadData();
        }
      },
      deep: true,
    },
  },
  computed: {
    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
    serverFormatDate() {
      if (!this.date) {
        return null;
      }

      return this.date.format("YYYY-MM-DD");
    },
    disableResetButton() {
      return JSON.stringify(filtersBaseState) == JSON.stringify(this.filters);
    },
  },
  methods: {
    loadData() {
      this.loading = true;
      this.apiGetAssociateOverviewInformation(
        this.serverFormatDate,
        this.filters
      )
        .then(({ data }) => {
          if (data.error_code && data.error_code !== "0") {
            notification["warning"]({
              message: "Error",
              description:
                "An error occurred while receiving associate activity. Please try again later",
            });
          }

          this.tableData = data.items;
        })
        .finally(() => (this.loading = false));
    },
    convertStatusToText(status) {
      return associateStatusMap[status] ?? status;
    },
    getTagColor(status) {
      return associateStatusColorsMap[status] ?? "#808080";
    },

    applyFilters(filters) {
      this.hideFilterModal();
      this.filters = { ...filters };
    },
    showFilterModal() {
      this.filtersModalVisible = true;
    },
    hideFilterModal() {
      this.filtersModalVisible = false;
    },
    resetFilters() {
      this.filters = { ...filtersBaseState };
    },

    openAssociateProfile(number) {
      this.associateNumberToShow = number;
      this.visibleProfileModal = true;
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
