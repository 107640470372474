<template>
  <a-modal
    class="shift-modal"
    :title="false"
    :visible="visible"
    :destroy-on-close="true"
    width="1200px"
    @cancel="closeShiftModal"
  >
    <div
      v-if="loadingShift || !shift"
      class="loading-spin"
      style="height: 650px; background-color: white"
    >
      <a-spin />
    </div>
    <div v-else>
      <shift-deletion
        :shift-id="shift.id"
        :visible.sync="visibleDeletionModal"
        :version="shift.version"
        @edit="afterDeletion"
      />

      <shift-edit
        :shift-id="shift.id"
        :visible.sync="visibleEditModal"
        @edit="afterEdit"
      />

      <shift-sign
        :shift-id="shift.id"
        :visible.sync="visibleSignModal"
        @edit="afterEdit"
      />

      <pay-code-edit
        v-if="hasPayCode"
        :shift-id="shift.id"
        :visible.sync="visibleEditPayCodeModal"
        @edit="afterEdit"
      />

      <div class="flex align-items-center gap-2 mb-4 pl-4">
        <h2 class="m-0">{{ modalTitle }}</h2>
        <a-tag v-if="shift.adjusted" class="m-0" color="#ffb546"
          >Adjusted</a-tag
        >
        <a-tag v-if="shift.manually_created" class="m-0" color="#52bfff"
          >Manually Created</a-tag
        >
        <a-tag v-if="shift.retro_shift" class="m-0" color="#b4b4b4"
          >Retro Shift</a-tag
        >
        <a-tag v-if="isDeleted" class="m-0" color="#ff4646">Deleted</a-tag>
      </div>
      <a-tabs default-active-key="info" size="small" tabPosition="left">
        <a-tab-pane key="info">
          <span slot="tab">
            <a-icon type="info-circle" />
            Info
          </span>
          <info-view />
        </a-tab-pane>
        <a-tab-pane key="time">
          <span slot="tab">
            <a-icon type="clock-circle" />
            Time
          </span>
          <time-view />
        </a-tab-pane>
        <a-tab-pane :disabled="hasPayCode" key="attachment">
          <span slot="tab">
            <a-icon type="folder-open" />
            Attachments
          </span>
          <attachment-view />
        </a-tab-pane>
        <a-tab-pane :disabled="hasPayCode || isDeleted" key="authorizations">
          <span slot="tab">
            <a-icon type="exception" />
            Authorizations
          </span>
          <authorization-view @update="afterEdit" />
        </a-tab-pane>
        <a-tab-pane :disabled="hasPayCode || isDeleted" key="paycode"
          ><span slot="tab">
            <a-icon type="link" />
            Pay Codes
          </span>
          <pay-codes-view />
        </a-tab-pane>
        <a-tab-pane key="calculation" :disabled="isDeleted"
          ><span slot="tab">
            <a-icon type="calculator" />
            Calculations
          </span>
          <calculation-view />
        </a-tab-pane>
        <a-tab-pane key="payment" :disabled="isDeleted">
          <span slot="tab">
            <a-icon type="dollar" />
            Payment </span
          ><payment-view />
        </a-tab-pane>
        <a-tab-pane :disabled="hasPayCode || isDeleted" key="action"
          ><span slot="tab">
            <a-icon type="history" />
            Actions
          </span>
          <action-view />
        </a-tab-pane>
      </a-tabs>
    </div>

    <template slot="footer">
      <div class="flex justify-content-between" v-if="isDeleted">
        <a-button @click="closeShiftModal">Close</a-button>
      </div>
      <div class="flex justify-content-between" v-else>
        <a-button @click="closeShiftModal">Close</a-button>
        <div class="flex justify-content-end">
          <a-button
            icon="delete"
            type="danger"
            ghost
            :disabled="!shift"
            @click="openDeletionModal"
            >Delete</a-button
          >
          <a-button
            v-if="shift && shift.status === 'not_signed'"
            icon="form"
            type="primary"
            :disabled="!shift"
            ghost
            @click="openSignModal"
            >Sign</a-button
          >

          <a-button
            v-if="shift && hasPayCode"
            icon="form"
            type="primary"
            :disabled="!shift"
            ghost
            @click="openEditModal"
            >Edit Pay Code</a-button
          >

          <a-button
            v-if="shift && !hasPayCode"
            icon="form"
            type="primary"
            :disabled="!shift"
            @click="openEditModal"
            ghost
            >Adjust</a-button
          >
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script>
import {
  Button,
  Icon,
  Modal,
  notification,
  Spin,
  Tabs,
  Tag,
} from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";
import ShiftViewCalculation from "./shift-view-calculation.vue";
import ShiftViewAttachments from "./shift-view-attachments.vue";
import ShiftViewPayCode from "./shift-view-pay-code.vue";
import ShiftViewPayment from "./shift-view-payment.vue";
import ShiftViewAction from "./shift-view-action.vue";
import ShiftViewAuthorization from "./shift-view-authorization.vue";
import ShiftViewInfo from "./shift-view-info.vue";
import ShiftViewTime from "./shift-view-time.vue";
import ShiftDeletionModal from "./shift-delete-modal.vue";
import ShiftSignModal from "./shift-sign-modal.vue";
import ShiftPayCodeEditModal from "./shift-pay-code-edit-modal.vue";
import shiftFormVue from "./shift-form.vue";

const {
  mapActions: shiftActions,
  mapState: shiftState,
  mapMutations: shiftMutations,
} = createNamespacedHelpers("shifts");

export default {
  components: {
    "a-modal": Modal,
    "a-button": Button,
    "a-tabs": Tabs,
    "a-tab-pane": Tabs.TabPane,
    "a-spin": Spin,
    "a-tag": Tag,
    "a-icon": Icon,
    "calculation-view": ShiftViewCalculation,
    "attachment-view": ShiftViewAttachments,
    "pay-codes-view": ShiftViewPayCode,
    "payment-view": ShiftViewPayment,
    "action-view": ShiftViewAction,
    "authorization-view": ShiftViewAuthorization,
    "info-view": ShiftViewInfo,
    "time-view": ShiftViewTime,
    "shift-deletion": ShiftDeletionModal,
    "shift-sign": ShiftSignModal,
    "pay-code-edit": ShiftPayCodeEditModal,
    "shift-edit": shiftFormVue,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    shift_id: {
      type: String,
      default: undefined,
    },
    edit: {
      type: Function,
    },
  },
  data() {
    return {
      loadingShift: false,

      visibleDeletionModal: false,
      visibleEditModal: false,
      visibleSignModal: false,
      visibleEditPayCodeModal: false,
    };
  },
  watch: {
    shift_id: {
      handler(newVal) {
        if (newVal) this.fetchShift();
      },
      immediate: true,
    },
    visible(newVal) {
      if (!this.shift && newVal) this.fetchShift();
    },
  },
  computed: {
    ...shiftState({
      shift: (state) => state.shift.shift,
      associate: (state) => state.shift.associate,
    }),

    modalTitle() {
      return this.shift
        ? `${this.associate.first_name} ${this.associate.last_name}\`s Shift №${this.shift.id}`
        : "Shift";
    },

    hasPayCode() {
      return this.shift.pay_code !== null;
    },

    isDeleted() {
      return this.shift ? this.shift.status === "deleted" : true;
    },
  },
  methods: {
    ...shiftActions([
      "getShiftWithAuth",
      "getShiftPayCodes",
      "getShiftCalculations",
      "getShiftPayments",
      "getShiftActions",
      "fetchShiftAuthorizations",
    ]),
    ...shiftMutations(["clearShiftData"]),

    openDeletionModal() {
      this.visibleDeletionModal = true;
    },

    afterDeletion() {
      this.closeShiftModal();
      this.$emit("edit");
    },

    openEditModal() {
      if (this.hasPayCode) {
        this.visibleEditPayCodeModal = true;
      } else {
        this.visibleEditModal = true;
      }
    },

    async afterEdit(withAdjust) {
      await this.fetchShift();

      this.$emit("edit");

      if (withAdjust) {
        this.openEditModal();
      }
    },

    openSignModal() {
      this.visibleSignModal = true;
    },

    closeShiftModal() {
      this.clearShiftData();
      this.$emit("update:visible", false);
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    async fetchShift() {
      try {
        this.loadingShift = true;

        const payload = {
          shift_id: this.shift_id,
        };

        await this.getShiftWithAuth(payload);

        if (this.isDeleted) {
          return;
        }

        await Promise.all([
          this.getShiftPayCodes(payload),
          this.getShiftCalculations(payload),
          this.getShiftPayments(payload),
          this.getShiftActions(payload),
          this.fetchShiftAuthorizations(payload),
        ]);
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingShift = false;
      }
    },
  },
};
</script>

<style scoped>
.shift-modal ::v-deep .ant-modal-body {
  padding-left: 0;
}

.shift-modal ::v-deep .ant-tabs-tab {
  text-align: left !important;
}

.shift-modal ::v-deep .ant-tabs-tab-active {
  background-color: #e6f7ff;
}

.ant-tag {
  height: fit-content;
}
</style>
