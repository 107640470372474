<template>
  <a-modal title="Mark Shifts as Paid" :visible="visible" @cancel="closeModal">
    <a-form-model
      layout="horizontal"
      :model="form"
      :rules="rules"
      ref="formRef"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-form-model-item
        :colon="false"
        label-align="left"
        label="Type"
        prop="payment_type"
      >
        <a-select
          :disabled="disabledPaymentType"
          v-model="form.payment_type"
          placeholder="Payment Type"
        >
          <a-select-option
            v-for="type in paymentTypes"
            :key="type"
            :value="type"
          >
            {{ type }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <div class="wrapper" v-show="form.payment_type === 'Contractor'">
        <a-form-model-item
          :colon="false"
          label-align="left"
          label="Payment System"
          prop="payment_system"
        >
          <a-select v-model="form.payment_system" placeholder="Payment System">
            <a-select-option
              v-for="system in systems"
              :key="system.id"
              :value="system.id"
            >
              {{ capitalize(system.name) }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
          :colon="false"
          label-align="left"
          label="Reference Number"
          prop="reference_number"
        >
          <a-input
            v-model="form.reference_number"
            placeholder="Reference Number"
          />
        </a-form-model-item>
      </div>

      <div class="wrapper" v-show="form.payment_type === 'Employee'">
        <a-form-model-item
          :colon="false"
          label-align="left"
          label="Selection by"
        >
          <a-radio-group v-model="selectionBy">
            <a-radio-button value="date_range">Date Range</a-radio-button>
            <a-radio-button value="associate">Associate</a-radio-button>
          </a-radio-group>
        </a-form-model-item>

        <div v-show="selectionBy === 'date_range'">
          <a-form-model-item
            :colon="false"
            label-align="left"
            label="Date Range"
            prop="date_range"
          >
            <a-range-picker
              v-model="form.date_range"
              separator="-"
              format="MM/DD/YYYY"
            >
            </a-range-picker>
          </a-form-model-item>
        </div>
      </div>

      <div v-if="showSelectedShifts">
        <a-form-model-item
          :colon="false"
          label-align="left"
          label="Associate"
          prop="associate"
        >
          <search-associates
            :value.sync="form.associate"
            :payment_type="form.payment_type"
          />
        </a-form-model-item>

        <a-table
          :row-selection="{
            selectedRowKeys: form.shifts,
            onChange: onSelectChange,
            getCheckboxProps: (record) => ({
              props: { disabled: record.shift.status === 'deleted' },
            }),
          }"
          :rowKey="(record) => record.shift.id"
          :locale="{ emptyText: 'Shifts Not Found' }"
          :loading="loadingShifts"
          size="small"
          :pagination="pagination"
          :columns="columns"
          :data-source="associateShifts"
          :scroll="{ y: 200 }"
          @change="handleTableChange"
        >
          <span slot="date" slot-scope="text, record">
            {{ formatDate(record.shift.scheduled_start) }} -
            {{ formatDate(record.shift.scheduled_end) }}
          </span>
          <span slot="status" slot-scope="text, record">
            <a-tooltip>
              <a-tag
                class="auth-tag"
                :color="
                  record.shift.status !== 'completed'
                    ? getShiftStatus(record.shift.status).color
                    : getShiftPaymentRequestStatus(record.shift.payment_status)
                        .color
                "
              >
                {{
                  record.shift.status !== "completed"
                    ? getShiftStatus(record.shift.status).title
                    : getShiftPaymentRequestStatus(record.shift.payment_status)
                        .title
                }}
              </a-tag>
            </a-tooltip>
          </span>
          <template slot="footer">
            <span v-if="hasSelected">{{
              `Selected ${form.shifts.length} shifts`
            }}</span>
          </template>
        </a-table>

        <a-form-model-item
          :labelCol="{ span: 0 }"
          :colon="false"
          label-align="left"
          prop="shifts"
        >
        </a-form-model-item>
      </div>

      <div class="wrapper" v-show="form.payment_type === 'Contractor'">
        <a-alert
          message="Please attach the paper or scanned invoice that you used to make the payment for the shifts"
          type="info"
          show-icon
        />

        <attachments-field
          :files="form.files"
          @save="saveAttachmentsIds"
          :with-title="false"
        />
      </div>
    </a-form-model>

    <template slot="footer">
      <div class="flex justify-content-between">
        <a-button @click="closeModal">Cancel</a-button>
        <a-button :loading="loading" @click="submit" type="primary"
          >Mark as Paid</a-button
        >
      </div>
    </template>
  </a-modal>
</template>

<script>
import ShiftFormAttachments from "@/modules/shifts/components/shift-form-attachments.vue";
import util from "@/util";
import {
  Alert,
  Button,
  DatePicker,
  FormModel,
  Input,
  Modal,
  notification,
  Radio,
  Select,
  Table,
  Tag,
  Tooltip,
} from "ant-design-vue";
import moment from "moment-timezone";
import { createNamespacedHelpers } from "vuex";
import { showActionNotification } from "../../../utils/action-notification";
import PaymentMarkShiftSearch from "./payment-mark-shift-search.vue";

const { mapState: paymentState, mapActions: paymentActions } =
  createNamespacedHelpers("payment");
const { mapActions: shiftActions, mapGetters: shiftGetters } =
  createNamespacedHelpers("shifts");
const { mapState: searchState } = createNamespacedHelpers("search");

const initialForm = {
  payment_type: "Contractor",
  associate: undefined,
  date_range: [],
  payment_system: undefined,
  shifts: [],
  reference_number: undefined,
  files: [],
};

const initialRules = {
  payment_type: [
    {
      required: true,
      message: "Please select payment type",
      trigger: "change",
    },
  ],
};

const contractorRules = {
  associate: [
    {
      required: true,
      message: "Please select associate",
      trigger: "change",
    },
  ],
  payment_system: [
    {
      required: true,
      message: "Please select system",
      trigger: "change",
    },
  ],
};

const employeeDatesRules = {
  date_range: [
    {
      required: true,
      message: "Please select date range",
      trigger: "change",
      type: "array",
    },
  ],
};

const employeeShiftsRules = {
  shifts: [
    {
      required: true,
      message: "Please select shifts",
      trigger: "change",
      type: "array",
    },
  ],
};

const initialPagination = {
  pageSizeOptions: ["5", "10", "15"],
  showSizeChanger: true,
  pageSize: 5,
  current: 1,
  total: 0,
};

export default {
  components: {
    "a-modal": Modal,
    "a-form-model": FormModel,
    "a-form-model-item": FormModel.Item,
    "a-button": Button,
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-input": Input,
    "a-alert": Alert,
    "a-radio-group": Radio.Group,
    "a-radio-button": Radio.Button,
    "a-range-picker": DatePicker.RangePicker,
    "a-table": Table,
    "a-tag": Tag,
    "a-tooltip": Tooltip,
    "search-associates": PaymentMarkShiftSearch,
    "attachments-field": ShiftFormAttachments,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: { ...initialForm },
      rules: { ...initialRules },

      paymentTypes: this.$store.state.applicationState.paymentTypes.map(
        (item) => item.name
      ),
      dateFormat: this.$store.state.applicationState.dateFormat,
      serverDateFormat: this.$store.state.applicationState.serverDateFormat,

      selectionBy: "date_range",

      loadingShifts: false,
      loading: false,

      associateShifts: [],

      pagination: initialPagination,
      columns: [
        {
          title: "Shift ID",
          dataIndex: "shift.id",
          align: "center",
        },
        {
          title: "Date",
          scopedSlots: { customRender: "date" },
          align: "center",
        },
        {
          title: "Status",
          scopedSlots: { customRender: "status" },
          align: "center",
        },
      ],

      projectPaymentType:
        this.$store.state.applicationState.currentPaymentTypeName,
    };
  },
  watch: {
    "$store.state.applicationState.currentPaymentTypeName": {
      handler(newVal) {
        this.form.payment_type = newVal === "All" ? "Contractor" : newVal;
      },
      immediate: true,
    },
    systems: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.form.payment_system = newVal.find(
            (item) => item.name === "Manual"
          )?.id;
        }
      },
      deep: true,
      immediate: true,
    },
    "form.associate": {
      handler(newVal) {
        this.form.shifts = [];

        if (newVal) {
          this.fetchShifts();
        }
      },
      deep: true,
      immediate: true,
    },
    "form.payment_type": {
      handler(newVal) {
        this.form.associate = undefined;
        this.form.shifts = [];
        this.form.associateShifts = [];

        if (newVal === "Contractor") {
          this.rules = { ...initialRules, ...contractorRules };
        } else if (newVal === "Employee") {
          const newRules =
            this.selectionBy === "date_range"
              ? employeeDatesRules
              : employeeShiftsRules;

          this.rules = { ...initialRules, ...newRules };
        }
      },
      immediate: true,
    },
    selectionBy: {
      handler(newVal) {
        if (this.form.payment_type !== "Employee") return;

        const newRules =
          newVal === "date_range" ? employeeDatesRules : employeeShiftsRules;

        this.rules = { ...initialRules, ...newRules };
      },
      immediate: true,
    },
  },
  computed: {
    ...searchState({
      users: (state) => state.users,
    }),

    ...paymentState({
      systems: (state) => state.systems,
    }),

    ...shiftGetters([
      "getShiftStatus",
      "getOriginColor",
      "getShiftPaymentRequestStatus",
    ]),

    hasSelected() {
      return this.form.shifts.length > 0;
    },

    disabledPaymentType() {
      return (
        this.$store.state.applicationState.currentPaymentTypeName !== "All"
      );
    },

    showSelectedShifts() {
      return (
        this.form.payment_type === "Contractor" ||
        (this.form.payment_type === "Employee" &&
          this.selectionBy === "associate")
      );
    },
  },
  methods: {
    ...shiftActions(["getShifts", "markShiftsAsPaid"]),
    ...paymentActions(["getPaymentSystems"]),

    capitalize: util.capitalize,
    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    closeModal() {
      this.selectionBy = "date_range";
      this.form = { ...initialForm };
      this.rules = { ...initialRules };
      this.$emit("update:visible", false);
    },

    saveAttachmentsIds(ids) {
      this.form.files = ids;
    },

    disabledStartDate(current) {
      const endValue = this.filters.end_date;
      if (!endValue) {
        return false;
      }

      return current && current.valueOf() > moment(endValue).valueOf();
    },

    disabledEndDate(current) {
      const startValue = this.filters.start_date;
      if (!startValue) {
        return false;
      }

      return current && current.valueOf() <= moment(startValue).valueOf();
    },

    formatDate(date) {
      return moment(date).format(this.$store.state.applicationState.dateFormat);
    },

    onSelectChange(selectedRowKeys) {
      this.form.shifts = selectedRowKeys;
    },

    handleTableChange(pagination) {
      const isPaginationChanged =
        pagination.current !== this.pagination.current ||
        pagination.pageSize !== this.pagination.pageSize;
      if (!isPaginationChanged) {
        return;
      }
      this.pagination = { ...pagination };
      this.fetchShifts();
    },

    async fetchShifts() {
      try {
        this.loadingShifts = true;
        const payload = {
          filter: {
            associate_id: this.form.associate,
          },
          page: this.pagination.current,
          size: this.pagination.pageSize,
        };
        const { items, total_count } = await this.getShifts(payload);

        this.associateShifts = items;
        this.pagination.total = total_count;
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingShifts = false;
      }
    },

    buildPayload(type) {
      const basePayload = { payment_type: this.form.payment_type };

      if (type === "Employee") {
        return {
          ...basePayload,
          ...(this.selectionBy === "date_range" && {
            date_range: this.form.date_range.map((item) =>
              item.format(this.serverDateFormat)
            ),
          }),
          ...(this.selectionBy === "associate" && {
            shifts: this.form.shifts,
          }),
        };
      }

      if (type === "Contractor") {
        return {
          ...basePayload,
          payment_system: this.form.payment_system,
          reference_number: this.form.reference_number,
          files: this.form.files.map((item) => item.id),
          shifts: this.form.shifts,
        };
      }

      return basePayload;
    },

    async submit() {
      Modal.confirm({
        title: "Mark Shift as Paid",
        content: "Are you sure you want to mark shifts as paid?",
        onOk: async () => {
          try {
            const valid = await new Promise((resolve) => {
              this.$refs.formRef.validate(resolve);
            });

            if (!valid) {
              return;
            }

            this.loading = true;

            const payload = this.buildPayload(this.form.payment_type);

            const data = await this.markShiftsAsPaid(payload);

            showActionNotification(
              "Success",
              [
                {
                  text: "View Payments",
                  type: "primary",
                  action: () => {
                    this.$router
                      .push({
                        path: "/payments-history",
                        query: {
                          request_ids: data.payment_request_ids,
                        },
                      })
                      .catch(() => {});
                  },
                },
              ],
              "Payment(s) successfully recorded!",
              this.$createElement
            );

            showActionNotification(
              "Success",
              [
                {
                  text: "View Shifts",
                  type: "primary",
                  action: () => {
                    this.$router
                      .push({
                        path: "/shifts",
                        query: { shift_ids: data.shift_ids },
                      })
                      .catch(() => {});
                  },
                },
              ],
              "Shifts successfully paid for!",
              this.$createElement
            );

            this.closeModal();
          } catch (error) {
            this.showNotification("error", "Error", error);
          } finally {
            this.loading = false;
          }
        },
        onCancel() {},
      });
    },

    async init() {
      try {
        await this.getPaymentSystems();
      } catch (error) {
        this.showNotification("error", "Error", error);
      }
    },
  },
  mounted() {
    if (!this.systems || !this.systems.length) this.init();
  },
};
</script>

<style scoped>
.ant-row {
  display: flex;
  margin: 0 !important;
}

.ant-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}

.ant-calendar-picker {
  margin: 0;
  width: 100%;
}

.ant-input-number {
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
