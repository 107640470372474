export const WRITEUP_REASON = Object.freeze({
  earlyStartAuth:
    "Starting shifts earlier than scheduled without prior authorization violates company policy and labor laws, potentially leading to unplanned overtime costs. This action disrupts the planned work schedule and financial budgeting.",
  noActualTimeAuth:
    "Adding shifts without tracked worked time constitutes a breach of company policy and can lead to payroll errors. This action disrupts accurate payroll processing and compliance with labor laws, potentially leading to financial discrepancies.",
  lateEndAuth:
    "Ending shifts later than scheduled without prior authorization leads to unauthorized overtime, violating company policies and labor laws. This can incur unnecessary financial costs and disrupt operational planning.",
  overtimeAuth:
    "Working overtime without prior approval contravenes our company’s policies and can result in additional financial burdens due to increased labor costs, violating labor laws.",
  outsideWorkAreaAuth:
    "Performing work outside the designated area without authorization violates company policy and can compromise safety standards, leading to potential legal and financial repercussions.",
  absenceOnShiftAuth:
    "Unexplained or unauthorized absences during scheduled shifts disrupt operations and contravene company policies and labor agreements, leading to potential financial and operational losses.",
  earlyLunchEndAuth:
    "Ending meal breaks earlier than the minimum required duration without authorization can lead to non-compliance with labor laws, affecting employee well-being and incurring potential legal penalties.",
  noLunchAuth:
    "Skipping mandated meal breaks contravenes labor laws and company policy, risking employee health and potentially incurring legal penalties.",
  firstLunchPeriodAuth:
    "Working without taking meal break for {firstLunchPeriod} can lead to non-compliance with labor laws, affecting employee well-being and incurring potential legal penalties.",
  earlySecondLunchEndAuth:
    "Ending the second meal break earlier than required without authorization overlooks labor laws and company policy, jeopardizing employee welfare and attracting legal consequences.",
  noSecondLunchAuth:
    "Failing to take a second meal break on extended shifts without proper authorization violates labor regulations and company policies, leading to potential health issues and legal penalties.",
  lateStartAuth:
    "Arriving and starting work late disrupts team dynamics, affects service delivery, and violates company timekeeping policies.",
  earlyEndAuth:
    "Leaving work before the scheduled end time without prior approval affects operational continuity and violates our commitment to reliability and professionalism.",
  timeMismatchAuth:
    "Mismatch between the time you signed for your shift and the time tracked by our system undermines the integrity of work records and breaches our standards for accuracy and accountability.",
  noRestBreakAuth:
    "Missing your designated rest breaks breaches company policy and undermines our commitment to employee well-being. This failure impacts not only your health but also the integrity of our operational standards. Ensure to take all scheduled breaks to maintain compliance.",
  uncompletedRequiredTasksAuth:
    "Failure to complete critical tasks violates company policy and undermines our commitment to employee well-being. Such a violation affects the integrity of our operating standards. Ensure that you complete all critical tasks.",
  associateNotRecognizedAuth:
    "Failure to properly clock in or be recognized by the system violates company policy and creates discrepancies in attendance tracking. This issue can lead to payroll inaccuracies and disrupts our operational efficiency. It's essential that all employees ensure they are properly logged in at the start of their shifts.",
  attachmentsMissingAuth:
    "Failure to attach required files violates company policy and creates discrepancies in attendance tracking. This issue can lead to payroll inaccuracies and disrupts our operational efficiency. It's essential that all employees ensure they are properly attach files to their shifts.",
  unscheduledShift:
    "Failure to submit the required Unscheduled Shift Authorization form violates company policy and disrupts proper attendance tracking. This oversight can result in payroll inaccuracies and compromises our ability to maintain an efficient and fair scheduling system. It is critical that all employees adhere to policy by submitting the necessary documentation for any unscheduled shifts.",
});

export const LEVEL_OF_PERFORMANCE = Object.freeze({
  earlyStartAuth:
    "Ensure to begin your shifts exactly as scheduled. Seek and obtain proper authorization if an early start is necessary due to exceptional circumstances. Adherence to the scheduled start times ensures compliance with labor regulations and company policies.",
  noActualTimeAuth:
    "Ensure that any shifts added without tracked worked time must be verified and authorized before being processed for payment. Compliance with this process is crucial to maintain accurate financial records and adherence to labor regulations.",
  lateEndAuth:
    "Conclude your shifts on time as per the schedule. If unforeseen circumstances require you to work longer, ensure you receive approval in advance. Timely completion of shifts helps in maintaining operational efficiency and cost-effectiveness.",
  overtimeAuth:
    "Adhere strictly to your assigned work hours unless overtime is approved by management. This adherence is essential for budget control and legal compliance.",
  outsideWorkAreaAuth:
    "Remain within your assigned work area during shifts. If your duties require relocation, seek immediate authorization. This compliance ensures safety and operational efficiency.",
  absenceOnShiftAuth:
    "Maintain consistent attendance and notify management ahead of time if you cannot attend a shift, providing a valid reason. Reliable attendance is crucial for operational continuity and team reliability.",
  earlyLunchEndAuth:
    "Utilize the full duration of your meal break as scheduled. If circumstances require adjustment, seek authorization. Proper breaks are vital for your health and compliance with labor regulations.",
  noLunchAuth:
    "Take your scheduled meal breaks to ensure compliance with labor laws and maintain your health. If workload demands flexibility, discuss it with management to find a lawful solution.",
  firstLunchPeriodAuth:
    "Take your scheduled meal breaks to ensure compliance with labor laws and maintain your health. If workload demands flexibility, discuss it with management to find a lawful solution.",
  earlySecondLunchEndAuth:
    "Adhere to the full duration of both your meal breaks, as mandated for longer shifts. Authorization must be obtained for any deviations to ensure well-being and legal compliance.",
  noSecondLunchAuth:
    "Ensure to take all mandated breaks during extended shifts. If operational demands require flexibility, seek approved adjustments. Compliance with break schedules is essential for your health and adherence to labor laws.",
  lateStartAuth:
    "Employees must arrive and be ready to start work at their scheduled start time. Late arrivals must be communicated to and approved by a supervisor in advance.",
  earlyEndAuth:
    "Remain engaged in work tasks up to the scheduled end time. Early departures must be authorized by a supervisor and recorded accurately in timekeeping systems.",
  timeMismatchAuth:
    "Ensure accuracy in shift records by matching the signed shift time with the actual time tracked by our system. Mismatch can compromise operational integrity and require immediate correction to uphold our commitment to precise timekeeping and transparency.",
  noRestBreakAuth:
    "Ensure you take all scheduled rest breaks to comply with company policy and labor regulations. Not adhering to break schedules can compromise your health and work performance. Regular breaks are essential to uphold our commitment to a healthy and productive work environment.",
  uncompletedRequiredTasksAuth:
    "Perform all critical tasks during your shift. If your duties do not allow you to perform them, seek permission immediately. Observing these rules ensures safe and efficient work.",
  associateNotRecognizedAuth:
    "Ensure you properly clock in and confirm that the system has recognized your attendance at the start of each shift. If any issues arise, report them immediately to your supervisor to prevent further discrepancies. Consistently adhering to the correct clock-in procedure is necessary for maintaining accurate records and smooth operations.",
  attachmentsMissingAuth:
    "Ensure you properly attach required files to the shift. If any issues arise, report them immediately to your supervisor to prevent further discrepancies. Consistently adhering to the correct files attaching is necessary for maintaining accurate records and smooth operations.",
  unscheduledShift:
    "Ensure that you submit the required Unscheduled Shift Authorization form promptly for any unscheduled shifts worked. If you encounter any difficulties, report them immediately to your supervisor to avoid discrepancies. Consistently following this process is essential for maintaining accurate attendance records and ensuring smooth operations.",
});

export const SCHEDULED_TYPE = Object.freeze({
  fixed: "Strict",
  self_schedule: "Flexible",
  free: "Free",
});

export const ASSOCIATE_STATUS = Object.freeze({
  active: {
    title: "ACTIVE",
    color: "#34db30",
  },
  suspended: {
    title: "SUSPENDED",
    color: "#ff4646",
  },
  terminated: {
    title: "TERMINATED",
    color: "#b4b4b4",
  },
  disabled: {
    title: "DISABLED",
    color: "#b4b4b4",
  },
});
