<template>
  <div>
    <h2 class="view-header">Associate</h2>
    <div class="table_controls_and_data">
      <div class="table_data_wrapper"></div>
      <div class="table_controls_wrapper">
        <div class="table_params">
          <a-dropdown :disabled="table_data.length == 0">
            <a-menu slot="overlay">
              <a-menu-item key="excel">
                <download-excel
                  :data="table_data"
                  worksheet="Associate"
                  name="employee_report.xls"
                >
                  <a-icon type="file-excel" />
                  Excel
                </download-excel>
              </a-menu-item>
              <a-menu-item key="csv">
                <download-excel
                  :data="table_data"
                  :escapeCsv="false"
                  type="csv"
                  name="employee_report.csv"
                >
                  <a-icon type="file-text" />
                  CSV
                </download-excel>
              </a-menu-item>
            </a-menu>
            <a-button style="margin-left: 8px" :disabled="loading">
              Export
              <a-icon type="down" />
            </a-button>
          </a-dropdown>
        </div>
        <div class="table_predefined_filters">
          <search-associates
            :multiply="true"
            style="min-width: 200px"
            :value.sync="selected_employees"
          />
        </div>
      </div>
    </div>
    <a-table
      :scroll="{ x: 3000, y: 'calc(100dvh - 300px)' }"
      :columns="columns"
      :data-source="table_data"
      :loading="loading"
    >
      <span slot="active" slot-scope="active">
        <a-icon v-if="active == true" type="check" />
        <a-icon v-else-if="active == false" type="close" />
      </span>
    </a-table>
  </div>
</template>

<style scoped>
.ant-table td {
  white-space: nowrap;
}
</style>

<script>
import {
  Button,
  DatePicker,
  Dropdown,
  Icon,
  Menu,
  Select,
  Table,
} from "ant-design-vue";
import api from "@/api";
import JsonExcel from "vue-json-excel";
import moment from "moment";
import SearchAssociates from "@/components/search-associates.vue";

export default {
  components: {
    "a-button": Button,
    "a-date-picker": DatePicker,
    "a-select": Select,
    "a-menu": Menu,
    "a-menu-item": Menu.Item,
    "a-icon": Icon,
    "a-dropdown": Dropdown,
    "a-table": Table,
    "download-excel": JsonExcel,
    "search-associates": SearchAssociates,
  },

  data: () => {
    return {
      loading: false,

      table_data: [],

      selected_employees: [],

      show_inactive: false,
      not_found: false,

      work_date_format: "YYYY-MM-DD",
      lunch_date_format: "HH:mm",

      pay_statuses: new Map([
        [0, "On Call"],
        [1, "Full Time"],
        [2, "Part Time"],
        [3, "Terminated"],
      ]),

      columns: [
        {
          title: "Associate ID",
          dataIndex: "employee_id",
          key: "employee_id",
          sorter: (a, b) => a.employee_id - b.employee_id,
          scopedSlots: { customRender: "actions" },
        },
        {
          title: "First Name",
          dataIndex: "first_name",
          key: "first_name",
          sorter: (a, b) =>
            a.first_name === null
              ? -1
              : b.first_name === null
              ? 1
              : a.first_name.localeCompare(b.first_name),
        },
        {
          title: "Last Name",
          dataIndex: "last_name",
          key: "last_name",
          sorter: (a, b) =>
            a.last_name === null
              ? -1
              : b.last_name === null
              ? 1
              : a.last_name.localeCompare(b.last_name),
        },
        {
          title: "Payment Type",
          dataIndex: "payment_type",
          key: "payment_type",
          sorter: (a, b) =>
            a.payment_type === null
              ? -1
              : b.payment_type === null
              ? 1
              : a.last_name.localeCompare(b.payment_type),
        },
        {
          title: "Phone",
          dataIndex: "phone",
          key: "phone",
          sorter: (a, b) =>
            a.phone === null
              ? -1
              : b.phone === null
              ? 1
              : a.phone.localeCompare(b.phone),
        },
        {
          title: "Address 1",
          dataIndex: "address1",
          key: "address1",
          filters: [],
          onFilter: (value, record) =>
            String(record.address1).indexOf(value) === 0,
          sorter: (a, b) =>
            a.address1 === null
              ? -1
              : b.address1 === null
              ? 1
              : a.address1.localeCompare(b.address1),
        },
        {
          title: "Address 2",
          dataIndex: "address2",
          key: "address2",
          filters: [],
          onFilter: (value, record) =>
            String(record.address2).indexOf(value) === 0,
          sorter: (a, b) =>
            a.address2 === null
              ? -1
              : b.address2 === null
              ? 1
              : a.address2.localeCompare(b.address2),
        },
        {
          title: "City",
          dataIndex: "city",
          key: "city",
          filters: [],
          onFilter: (value, record) => String(record.city).indexOf(value) === 0,
          sorter: (a, b) =>
            a.city === null
              ? -1
              : b.city === null
              ? 1
              : a.city.localeCompare(b.city),
        },
        {
          title: "State",
          dataIndex: "state",
          key: "state",
          filters: [],
          onFilter: (value, record) =>
            String(record.state).indexOf(value) === 0,
          sorter: (a, b) =>
            a.state === null
              ? -1
              : b.state === null
              ? 1
              : a.state.localeCompare(b.state),
        },
        {
          title: "Zipcode",
          dataIndex: "zipcode",
          key: "zipcode",
          filters: [],
          onFilter: (value, record) =>
            String(record.zipcode).indexOf(value) === 0,
          sorter: (a, b) =>
            a.zipcode === null
              ? -1
              : b.zipcode === null
              ? 1
              : a.zipcode.localeCompare(b.zipcode),
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          sorter: (a, b) =>
            a.email === null
              ? -1
              : b.email === null
              ? 1
              : a.email.localeCompare(b.zipcode),
        },
        {
          title: "SSN",
          dataIndex: "soc_sec_num",
          key: "soc_sec_num",
          sorter: (a, b) => a.soc_sec_num - b.soc_sec_num,
        },
        {
          title: "Date of Birth",
          dataIndex: "birth_date",
          key: "birth_date",
          sorter: (a, b) =>
            a.birth_date === null
              ? -1
              : b.birth_date === null
              ? 1
              : a.birth_date.localeCompare(b.birth_date),
        },
        {
          title: "Hire Date",
          dataIndex: "hire_date",
          key: "hire_date",
          sorter: (a, b) =>
            a.birth_date === null
              ? -1
              : b.birth_date === null
              ? 1
              : a.birth_date.localeCompare(b.birth_date),
        },
        {
          title: "Active",
          dataIndex: "active",
          key: "active",
          scopedSlots: { customRender: "active" },
          align: "center",
          filters: [
            {
              text: "Yes",
              value: "true",
            },
            {
              text: "No",
              value: "false",
            },
          ],
          filterMultiple: false,
          onFilter: (value, record) => record.active.toString() == value,
        },
        {
          title: "Terminate Date",
          dataIndex: "terminate_date",
          key: "terminate_date",
          sorter: (a, b) =>
            a.terminate_date === null
              ? -1
              : b.terminate_date === null
              ? 1
              : moment(a.terminate_date).valueOf() -
                moment(b.terminate_date).valueOf(),
        },
        {
          title: "Seniority Date",
          dataIndex: "seniority_date",
          key: "seniority_date",
          sorter: (a, b) =>
            a.seniority_date === null
              ? -1
              : b.seniority_date === null
              ? 1
              : moment(a.seniority_date).valueOf() -
                moment(b.seniority_date).valueOf(),
        },
        {
          title: "Job classification",
          dataIndex: "role",
          key: "role",
          filters: [],
          onFilter: (value, record) => String(record.role).indexOf(value) === 0,
          sorter: (a, b) =>
            a.role === null
              ? -1
              : b.role === null
              ? 1
              : a.role.localeCompare(b.role),
        },
        {
          title: "Hourly Rate",
          dataIndex: "hour_rate",
          key: "hour_rate",
          sorter: (a, b) => a.hour_rate - b.hour_rate,
        },
      ],
    };
  },

  mixins: [api],

  watch: {
    selected_employees() {
      this.loadData();
    },
  },

  methods: {
    loadData() {
      this.not_found = false;
      this.table_data = [];
      this.loading = true;
      Object.assign(this.$data.columns, this.$options.data().columns);
      this.apiGetAssociateReport(this.selected_employees, this.show_inactive)
        .then((response) => {
          if (response.data && response.data.employee_records) {
            response.data.employee_records.forEach((record) => {
              this.table_data.push({
                employee_id: record.employee_id,
                last_name: record.last_name,
                first_name: record.first_name,
                payment_type: record.payment_type,
                phone: record.phone,
                address1: record.address1,
                address2: record.address2,
                city: record.city,
                state: record.state,
                zipcode: record.zipcode,
                email: record.email,
                soc_sec_num: record.soc_sec_num,
                birth_date: record.birth_date,
                hire_date: record.hire_date,
                active: record.active,
                terminate_date: record.terminate_date,
                seniority_date: record.seniority_date,
                role: record.role,
                hour_rate:
                  record.hour_rate != null
                    ? this.convertMoney(record.hour_rate)
                    : null,
                // salary: record.salary != null ? this.convertMoney(record.salary) : null,
                // shift_number: record.shift_number,
                // paystat: record.paystat,
                // paystat_desc: this.pay_statuses.get(record.paystat)
              });

              if (record.address1 != null && record.address1.length > 0) {
                let filters = this.columns.find(
                  (col) => col.key == "address1"
                ).filters;
                if (
                  filters.find((el) => el.value == record.address1) ===
                  undefined
                ) {
                  filters.push({
                    text: record.address1,
                    value: record.address1,
                  });
                }
              }

              if (record.address2 != null && record.address2.length > 0) {
                let filters = this.columns.find(
                  (col) => col.key == "address2"
                ).filters;
                if (
                  filters.find((el) => el.value == record.address2) ===
                  undefined
                ) {
                  filters.push({
                    text: record.address2,
                    value: record.address2,
                  });
                }
              }

              if (record.city != null && record.city.length > 0) {
                let filters = this.columns.find(
                  (col) => col.key == "city"
                ).filters;
                if (
                  filters.find((el) => el.value == record.city) === undefined
                ) {
                  filters.push({
                    text: record.city,
                    value: record.city,
                  });
                }
              }

              if (record.state != null && record.state.length > 0) {
                let filters = this.columns.find(
                  (col) => col.key == "state"
                ).filters;
                if (
                  filters.find((el) => el.value == record.state) === undefined
                ) {
                  filters.push({
                    text: record.state,
                    value: record.state,
                  });
                }
              }

              if (record.zipcode != null && record.zipcode.length > 0) {
                let filters = this.columns.find(
                  (col) => col.key == "zipcode"
                ).filters;
                if (
                  filters.find((el) => el.value == record.zipcode) === undefined
                ) {
                  filters.push({
                    text: record.zipcode,
                    value: record.zipcode,
                  });
                }
              }

              // if (record.shift_number != null) {
              //   const shift_number = String(record.shift_number)
              //   let filters = this.columns.find(col => col.key == 'shift_number').filters;
              //   if (filters.find(el => el.value == shift_number) === undefined) {
              //     filters.push({
              //       text: shift_number,
              //       value: shift_number
              //     })
              //   }
              // }

              // if (record.paystat != null) {
              //   let filters = this.columns.find(col => col.key == 'paystat').filters
              //   if (filters.find(el => el.value == record.paystat) === undefined) {
              //     filters.push({
              //       text: this.pay_statuses.get(record.paystat),
              //       value: record.paystat
              //     })
              //   }
              // }

              if (record.role != null) {
                let filters = this.columns.find(
                  (col) => col.key == "role"
                ).filters;
                if (
                  filters.find((el) => el.value == record.role) === undefined
                ) {
                  filters.push({
                    text: record.role,
                    value: record.role,
                  });
                }
              }
            });
          }
          if (this.table_data.length == 0) {
            this.not_found = true;
          }
          this.loading = false;
        })
        .catch((err) => console.error(err));
    },
    clearData() {
      this.not_found = false;
      this.table_data = [];
      this.selected_employees = [];
    },

    convertMoney(cents) {
      return (cents / 100).toFixed(2);
    },
  },

  mounted() {
    this.loadData();
  },
};
</script>
