<template>
  <div class="flex flex-column gap-4">
    <div
      class="flex flex-column gap-2"
      v-for="{ name, items } in groupedPermissions"
      :key="name"
    >
      <p class="text-lg font-bold m-0">{{ capitalize(name) }}:</p>

      <div>
        <div class="flex flex-column" v-for="item in items" :key="item.key">
          <div class="grid">
            <div class="col">
              <p class="text-base font-bold m-0">{{ item.title }}</p>
              <p class="role-property-description">
                {{ item.description }}
              </p>
            </div>
            <div class="col-fixed">
              <a-switch
                :disabled="isDisabled"
                :checked="hasRole(item.key)"
                @change="handleSwitchChange(item)"
              />
            </div>
          </div>
          <div class="grid" v-show="hasRole(item.key) && isVisibleScope">
            <div class="col">
              <p class="text-base font-bold m-0">Scope:</p>
            </div>
            <div class="col-fixed">
              <a-radio-group
                :value="findScope(item.key)"
                :disabled="isDisabled"
                size="small"
                @change="(value) => handleScopeChange(value, item.key)"
              >
                <a-radio-button value="all"> All </a-radio-button>
                <a-radio-button value="controlled"> Controlled </a-radio-button>
              </a-radio-group>
            </div>
          </div>

          <a-divider />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PERMISSIONS } from "@/store/modules/roles/constants";
import util from "@/util";
import { Divider, Radio, Switch } from "ant-design-vue";

export default {
  components: {
    "a-switch": Switch,
    "a-divider": Divider,
    "a-radio-group": Radio.Group,
    "a-radio-button": Radio.Button,
  },
  props: {
    permissions: {
      type: Array,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isVisibleScope: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    groupedPermissions() {
      const groups = Object.entries(PERMISSIONS).reduce((acc, [key, value]) => {
        const permission = { key, ...value };
        const groupName = value.group;

        if (!acc[groupName]) {
          acc[groupName] = { name: groupName, items: [] };
        }

        acc[groupName].items.push(permission);
        return acc;
      }, {});

      return Object.values(groups);
    },
    permissionsMap() {
      return new Map(this.permissions.map((item) => [item.name, item]));
    },
  },
  methods: {
    capitalize: util.capitalize,

    hasRole(key) {
      return this.permissionsMap.has(key);
    },

    findScope(key) {
      return this.permissionsMap.get(key)?.scope ?? "all";
    },

    handleSwitchChange(item) {
      const updatedPermissions = this.hasRole(item.key)
        ? this.permissions.filter((roleItem) => roleItem.name !== item.key)
        : [
            ...this.permissions,
            { name: item.key, scope: "all", group: item.group },
          ];

      this.$emit("update:permissions", updatedPermissions);
    },

    handleScopeChange(e, key) {
      const value = e.target.value;

      const updatedPermissions = this.permissions.map((item) => {
        if (item.name === key) {
          return { ...item, scope: value };
        }

        return item;
      });

      this.$emit("update:permissions", updatedPermissions);
    },
  },
};
</script>

<style scoped>
.ant-divider-horizontal {
  margin: 4px 0;
}
</style>
