<template>
  <div class="grid">
    <div class="col-3">
      <h3>Payment</h3>
      <a-descriptions :column="1" size="small" class="col-fixed p-0">
        <a-descriptions-item label="Type">
          {{ associate.payment_info.type }}
        </a-descriptions-item>
        <template v-if="associate.payment_info.legal_entity">
          <a-descriptions-item label="Subtype">
            {{
              associate.payment_info.legal_entity.type === "agency"
                ? "Agency"
                : "Sole Proprietor"
            }}
          </a-descriptions-item>
          <a-descriptions-item label="Agency">
            {{ associate.payment_info.legal_entity.name }}
          </a-descriptions-item>
        </template>

        <a-descriptions-item label="Status">
          {{ getPaymentStatus(associate.payment_info.status) }}
        </a-descriptions-item>
        <a-descriptions-item label="Method">
          {{ getPaymentMethod(associate.payment_info.method) }}
        </a-descriptions-item>
        <a-descriptions-item label="Hourly Rate, $">
          {{ associate.payment_info.hourly_rate / 100 }}
        </a-descriptions-item>
        <a-descriptions-item
          v-if="associate.payment_info.method === 'salary'"
          label="Salary for Pay Period, $"
        >
          {{
            associate.payment_info.salary
              ? associate.payment_info.salary / 100
              : " - "
          }}
        </a-descriptions-item>
        <a-descriptions-item label="Instant Payment">
          {{ associate.allow_instant_payment ? "Allow" : "Forbid" }}
        </a-descriptions-item>
      </a-descriptions>
    </div>
  </div>
</template>

<script>
import {
  PAYMENT_METHOD,
  PAYMENT_WORK_STATUS,
} from "@/store/modules/payment/constants";
import { Descriptions } from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";

const { mapState: profileState } = createNamespacedHelpers("profile");

export default {
  components: {
    "a-descriptions": Descriptions,
    "a-descriptions-item": Descriptions.Item,
  },
  computed: {
    ...profileState({
      associate: (state) => state.associate,
    }),
  },
  methods: {
    getPaymentMethod(method) {
      return PAYMENT_METHOD[method];
    },
    getPaymentStatus(status) {
      return PAYMENT_WORK_STATUS[status];
    },
  },
};
</script>

<style scoped>
.ant-descriptions {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

::v-deep .ant-descriptions-item-label {
  font-weight: bold;
  border: none;
  background-color: white;
}

::v-deep .ant-descriptions-row {
  border: none;
}

::v-deep .ant-descriptions-item-content {
  overflow-x: auto;
}

::v-deep .ant-descriptions-view {
  padding: 16px;
}

::v-deep .ant-descriptions-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
