import { vueTourOptions } from "@/const";

export default {
  data() {
    return {
      tourSteps: [
        {
          target: ".ant-table-row",
          header: {
            title: "Associate Profiles",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          before: this.profilesTourBeforeStartCallback,
          content: `
            <p>This section displays key information about each associate. Each row contains:</p>
            <p><b>Associate Number</b>: Unique identifier for the associate.</p>
            <p><b>Full Image</b>: Picture of the associate (if exists).</p>
            <p><b>Classification</b>: Job classification of the associate.</p>
            <p><b>Payment Type</b>: Method of payment (e.g., salary, hourly).</p>
            <p><b>Payment Status</b>: Current status of payment (e.g., active, suspended).</p>
            <p><b>Rate</b>: Pay rate of the associate.</p>
            <p><b>Schedule Type</b>: Type of work schedule the associate follows.</p>
          `,
        },
        {
          target: ".ant-table-row td:nth-child(7)",
          header: {
            title: "Associate Status",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content:
            "This field shows whether the associate is currently employed or has\n" +
            "been terminated.",
        },
        {
          target: "#edit-associate-profile",
          header: {
            title: "Associate Edit",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          before: () => {
            if (!this.editingAssociateNumber) {
              return;
            }
            return new Promise((resolve) => {
              this.endEditAssociateProfile();
              setTimeout(() => {
                resolve();
              }, 1000);
            });
          },
          content: `
          <p>Clicking 'Edit' allows you to view and modify various details about the associate, including:</p>
          <p><b>Personal Information</b>: Basic personal details of the associate.</p>
          <p><b>Job Information</b>: Details about the associate's job role and responsibilities.</p>
          <p><b>Payment Information</b>: Information related to the associate's payment method and status.</p>
          <p><b>Signed Documents</b>: Documents signed by the associate.</p>
          <p><b>Associate KPI</b>: Key Performance Indicators for the associate.</p>
          <p><b>Clone Associate</b>: This feature allows you to clone the associate with a different payment type, enabling them to work as both an employee and a contractor, or in different roles or for different companies.</p>
          `,
        },
        {
          target: ".profile-form-menu li:nth-child(1)",
          header: {
            title: "KPI",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          before: () => {
            return new Promise((resolve) => {
              this.startEditAssociateProfile(
                this.tableData[0].associate_number
              );
              setTimeout(() => {
                resolve();
              }, 1000);
            });
          },
          content: "Here you can view an associate's KPI",
        },
        {
          target: ".profile-form-menu li:nth-child(2)",
          header: {
            title: "Personal",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          before: () => {
            document
              .querySelector(".profile-form-menu li:nth-child(2)")
              .click();
          },
          content: "Here you can view an associate's personal information",
        },
        {
          target: ".profile-form-menu li:nth-child(3)",
          header: {
            title: "Job",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          before: () => {
            document
              .querySelector(".profile-form-menu li:nth-child(3)")
              .click();
          },
          content:
            "Here you can view an associate's job information (ex. job classification, schedule type)",
        },
        {
          target: ".profile-form-menu li:nth-child(4)",
          header: {
            title: "Pay",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          before: async () => {
            if (this.editingAssociateNumber) {
              document
                .querySelector(".profile-form-menu li:nth-child(4)")
                .click();
              return;
            }

            await new Promise((resolve) => {
              this.startEditAssociateProfile(
                this.tableData[0].associate_number
              );
              setTimeout(() => {
                resolve();
              }, 1000);
            });
            document
              .querySelector(".profile-form-menu li:nth-child(4)")
              .click();
          },
          content: "Here you can view an associate's payment information,",
        },
        {
          target: "#associate-shifts-btn",
          header: {
            title: "Associate Shifts",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          before: () => {
            return new Promise((resolve) => {
              this.endEditAssociateProfile();
              setTimeout(() => {
                resolve();
              }, 500);
            });
          },
          content:
            "This button lets you view all shifts worked by the associate.",
        },
        {
          target: "#send-message-btn",
          header: {
            title: "Send Message Button",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content:
            "Use this button to send a message to the associate, whether it's to\n" +
            "notify them of something or to request action.",
        },
        {
          target: "#note-btn",
          header: {
            title: "Note Button",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content: "This button allows you to leave a note for associate",
        },
        {
          target: "#active-btn",
          header: {
            title: "Terminate/Activate Associate Button",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content: "This button allows you to activate or terminate associate",
        },
      ],
    };
  },
  computed: {
    vueTourOptions() {
      return vueTourOptions;
    },
  },
  methods: {
    profilesTourBeforeStartCallback() {
      return new Promise((resolve, reject) => {
        const checkCondition = () => {
          if (this.tableData && this.tableData.length > 0 && !this.loading) {
            resolve();
          } else {
            setTimeout(checkCondition, 100);
          }
        };
        checkCondition();
      });
    },

    startTour() {
      this.$tours["profilesTour"].start();
    },
  },
};
