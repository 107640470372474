<template>
  <div class="column">
    <h3 v-if="withTitle" class="m-0">Attachments</h3>
    <div>
      <a-upload
        list-type="picture-card"
        :file-list="fileList"
        :before-upload="beforeUpload"
        :remove="handleRemove"
        :custom-request="handleUpload"
        accept=".mp4,.mov,.mkv,.wmv,.webm,.avi,.flv,.mts,.png,.jpg,.jpeg,.svg,.doc,.docx,.pdf,.xls,.xlsx"
        :showUploadList="{
          showRemoveIcon: true,
          showPreviewIcon: true,
          showDownloadIcon: true,
        }"
        :multiple="true"
        @preview="handlePreview"
        @change="handleChange"
        @download="handleDownload"
      >
        <div>
          <a-icon :type="loading ? 'loading' : 'plus'" />
          <div class="ant-upload-text">Upload</div>
        </div>
      </a-upload>

      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { Button, Icon, notification, Upload } from "ant-design-vue";

export default {
  components: {
    "a-upload": Upload,
    "a-button": Button,
    "a-icon": Icon,
  },
  props: {
    files: {
      type: Array,
      default: [],
    },
    withTitle: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [api],
  data() {
    return {
      previewVisible: false,

      previewImage: "",
      fileList: [],

      loading: false,
    };
  },
  methods: {
    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    handleCancel() {
      this.previewVisible = false;
    },

    handleRemove(file) {
      this.fileList = this.fileList.filter((item) => item.uid !== file.uid);
      this.$emit(
        "save",
        this.fileList.map((item) => ({ id: item.uid })),
        "remove"
      );
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        try {
          file.preview = await this.getBase64(file.originFileObj);
        } catch (error) {
          console.error("Failed to generate preview:", error);
          return;
        }
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },

    beforeUpload(file) {
      const allowedExtensions = [
        "mp4",
        "mov",
        "mkv",
        "wmv",
        "webm",
        "avi",
        "flv",
        "mts",
        "png",
        "jpg",
        "jpeg",
        "svg",
        "doc",
        "docx",
        "pdf",
        "xls",
        "xlsx",
      ];

      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        this.showNotification(
          "error",
          "Error",
          "This file type is not allowed."
        );
        return false;
      }
      return true;
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    async handleUpload({ file, onSuccess, onError }) {
      this.loading = true;
      try {
        const { data } = await this.apiUploadFiles([file]);
        if (!data || !data.ids || !data.ids.length) {
          throw new Error("Error occurred while uploading files.");
        }
        const fileData = { id: data.ids[0] };

        this.fileList = [
          ...this.fileList,
          {
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            name: file.name,
            size: file.size,
            type: file.type,
            originFileObj: file,
            uid: fileData.id,
            status: "done",
          },
        ];

        this.fileList = this.fileList.filter((item) => item.uid !== file.uid);

        this.$emit(
          "save",
          this.fileList
            .sort((a, b) => a.uid - b.uid)
            .map((item) => ({ id: item.uid })),
          "add"
        );
        onSuccess(fileData);
      } catch (error) {
        onError(error);
      } finally {
        this.loading = false;
      }
    },

    handleChange(info) {
      const { fileList } = info;
      this.fileList = fileList.map((file) => {
        if (file.type.startsWith("image/") && !file.url && !file.preview) {
          this.getBase64(file.originFileObj).then((preview) => {
            file.preview = preview;
          });
        }
        return file;
      });
    },

    async handleDownload(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }

      window.open(file.url || file.preview, "_blank");
    },
  },
  mounted() {
    this.fileList = this.files
      .map((item) => ({
        uid: item.id,
        name: item.url,
        url: item.url,
        status: "done",
      }))
      .sort((a, b) => a.uid - b.uid);
  },
};
</script>

<style scoped>
.column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
