<template>
  <div>
    <h3>Suspension</h3>
    <div class="flex flex-column gap-2" v-if="hasSuspension">
      <a-descriptions :column="1" size="small" class="col-fixed p-0">
        <a-descriptions-item label="Author">
          {{ suspensionAuthorName }}
        </a-descriptions-item>
        <a-descriptions-item label="Reason">
          {{ suspension.reason }}
        </a-descriptions-item>
        <a-descriptions-item label="Start Date">
          {{
            suspension?.start_date
              ? formatDateString(suspension?.start_date)
              : " - "
          }}
        </a-descriptions-item>
        <a-descriptions-item label="Duration">
          {{
            isSuspensionDurationIndefinite
              ? "Indefinite"
              : suspension.days_amount
          }}
        </a-descriptions-item>
        <a-descriptions-item
          v-if="!isSuspensionDurationIndefinite"
          label="Days left"
        >
          {{ suspension.remaining_days }}
        </a-descriptions-item>
      </a-descriptions>

      <div class="col-12 flex gap-2">
        <a-button icon="redo" @click="releaseFromSuspension"
          >Cancel Suspension</a-button
        >
      </div>
    </div>
    <div class="grid" v-else>
      <div class="col-12 flex gap-2" v-if="!creatingSuspension">
        <a-button icon="plus" @click="switchAddSuspension">Suspend</a-button>
      </div>
      <div class="grid" v-else>
        <b class="col-4"
          >Suspension Days
          <span v-show="!newSuspension.indefinite_period" class="required-field"
            >*</span
          ></b
        >
        <div class="col-8">
          <a-input-number
            class="w-full"
            v-model="newSuspension.days_amount"
            :min="1"
            :disabled="newSuspension.indefinite_period"
            placeholder="Set days"
          />
        </div>

        <b class="col-4">Indefinite Period</b>
        <div class="col-8">
          <a-switch v-model="newSuspension.indefinite_period" />
        </div>

        <b class="col-4">Reason <span class="required-field">*</span></b>
        <div class="col-8">
          <a-textarea
            v-model="newSuspension.reason"
            :autoSize="true"
            placeholder="Enter suspension reason"
          />
        </div>

        <b class="col-4">Start Date </b>
        <div class="col-8">
          <a-date-picker
            v-model="newSuspension.start_date"
            placeholder="Enter start date"
            class="w-full"
            :format="visualDateFormat"
          />
        </div>

        <div class="col-12 flex gap-2 justify-content-end">
          <a-button @click="cancelSuspension">Cancel</a-button>
          <a-button
            type="danger"
            @click="setSuspension"
            :disabled="saveSuspensionDisabled"
            >Suspend
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Util from "@/util";
import {
  Button,
  DatePicker,
  Descriptions,
  Input,
  InputNumber,
  Modal,
  notification,
  Switch,
} from "ant-design-vue";
import moment from "moment-timezone";
import { createNamespacedHelpers } from "vuex";

const { mapActions: profileActions } = createNamespacedHelpers("profile");

export default {
  components: {
    "a-button": Button,
    "a-date-picker": DatePicker,
    "a-textarea": Input.TextArea,
    "a-switch": Switch,
    "a-input-number": InputNumber,
    "a-descriptions": Descriptions,
    "a-descriptions-item": Descriptions.Item,
  },
  data() {
    return {
      creatingSuspension: false,
      newSuspension: {
        days_amount: undefined,
        indefinite_period: false,
        reason: undefined,
        start_date: undefined,
      },
    };
  },
  props: {
    suspension: {
      type: [Object, null],
      default: null,
    },
    associateNumber: {
      type: String,
      default: null,
    },
  },
  watch: {
    newSuspension: {
      handler() {
        if (this.newSuspension.indefinite_period) {
          this.newSuspension.days_amount = undefined;
        }
      },
      deep: true,
    },
  },
  computed: {
    hasSuspension() {
      return this.suspension !== null;
    },
    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
    suspensionAuthorName() {
      if (!this.hasSuspension) {
        return "-";
      }

      const { first_name, last_name } = this.suspension.author || {};
      return Util.combineAssociateName(first_name, last_name);
    },
    isSuspensionDurationIndefinite() {
      return Boolean(this.suspension.indefinite_period);
    },
    saveSuspensionDisabled() {
      const { days_amount, indefinite_period, reason } = this.newSuspension;

      const isDaysAmountValid = days_amount != null || indefinite_period;
      const isReasonValid = reason && reason.length > 0;

      return !(isDaysAmountValid && isReasonValid);
    },

    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
  },
  methods: {
    ...profileActions([
      "reloadAssociate",
      "postAssociateSuspension",
      "postAssociateReleaseSuspension",
    ]),

    showNotification(type, message, description) {
      notification[type]({
        message: message,
        description: description,
      });
    },

    formatDateString(dateString) {
      return moment(dateString).utc().format("MM/DD/YYYY");
    },

    async releaseFromSuspension() {
      try {
        await this.postAssociateReleaseSuspension({
          associate_number: this.associateNumber,
        });

        this.showNotification(
          "success",
          "Suspension Released",
          `Associate ${this.associateNumber} is now not suspended`
        );

        this.reloadAssociate(true);
      } catch (error) {
        this.showNotification(
          "error",
          "Suspension Release Error",
          "An unexpected error occurred"
        );
      }
    },

    switchAddSuspension() {
      this.creatingSuspension = !this.creatingSuspension;
    },

    setSuspension() {
      Modal.confirm({
        title: "Are you sure?",
        content: `You are trying to suspend an associate "${this.associateNumber}". If you are sure - click OK.`,
        onOk: async () => {
          try {
            await this.postAssociateSuspension({
              associate_number: this.associateNumber,
              suspension: this.newSuspension,
            });

            this.showNotification(
              "success",
              "Suspension Set",
              `Associate ${this.associateNumber} is now suspended`
            );

            this.resetNewSuspension();
            this.switchAddSuspension();
            this.reloadAssociate(true);
          } catch (error) {
            this.showNotification(
              "error",
              "Suspension Set Error",
              "An unexpected error occurred"
            );
          }
        },
        onCancel() {},
      });
    },
    resetNewSuspension() {
      this.newSuspension.days_amount = undefined;
      this.newSuspension.indefinite_period = false;
      this.newSuspension.reason = undefined;
    },
    cancelSuspension() {
      this.resetNewSuspension();
      this.switchAddSuspension();
    },
  },
};
</script>

<style scoped>
span {
  text-align: left !important;
}

.ant-calendar-picker {
  margin: 0;
  min-width: unset !important;
}

.ant-descriptions {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

::v-deep .ant-descriptions-item-label {
  font-weight: bold;
  border: none;
  background-color: white;
}

::v-deep .ant-descriptions-row {
  border: none;
}

::v-deep .ant-descriptions-item-content {
  overflow-x: auto;
}

::v-deep .ant-descriptions-view {
  padding: 16px;
}

::v-deep .ant-descriptions-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
