<template>
  <div class="user-avatar">
    <img
      alt="User Avatar"
      :src="imageSource"
      :style="{
        width: String(width ?? defaultSideLengthPx) + 'px',
        height: String(height ?? defaultSideLengthPx) + 'px',
      }"
    />
  </div>
</template>

<script>
export default {
  props: {
    imagePath: {
      type: String,
      required: false,
    },
    width: {
      type: Number,
      required: false,
    },
    height: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      defaultSideLengthPx: 40,
      imageSource: require("@/assets/img/avatar-placeholder.svg"),
    };
  },
  computed: {
    isLinkAvailable() {
      return this.imagePath != null && this.imagePath.length > 0;
    },
  },
  watch: {
    imagePath() {
      this.tryToGetAvatar();
    },
  },
  methods: {
    tryToGetAvatar() {
      if (this.isLinkAvailable) {
        const img = new Image();
        img.onload = () => {
          if (this.imagePath !== this.previousImagePath) {
            this.imageSource = this.imagePath;
            this.previousImagePath = this.imagePath;
          }
        };
        img.onerror = () => {
          this.imageSource = require("@/assets/img/avatar-placeholder.svg");
        };
        img.src = this.imagePath + "?timestamp=" + new Date().getTime();
      }
    },
  },
  mounted() {
    this.tryToGetAvatar();
  },
};
</script>

<style>
.user-avatar img {
  border-radius: 20%;
  object-fit: cover;
  border: 1px solid black;
}
</style>
