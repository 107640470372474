import apiClient from "@/services/api";
import moment from "moment-timezone";

export default {
  /**
   * Fetches authorizations for a specific shift and commits the data to the Vuex store.
   *
   * @param {Object} payload - Contains parameters for the request.
   * @param {number} payload.shift_id - The unique identifier of the shift.
   * @throws {string} Error message if an error occurs or the API returns a non-zero error code.
   */
  async fetchShiftAuthorizations({ commit }, { shift_id }) {
    try {
      const { data } = await apiClient.post("/api/get_current_shift_auths", {
        shift_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      commit("setShiftAuthorizations", data.authorizations);
    } catch (error) {
      throw error.message;
    }
  },

  /**
   * Deletes an employee shift by sending a request to the backend.
   *
   * @param {Object} payload - Contains parameters for the request.
   * @param {number} payload.shift_id - The unique identifier of the shift.
   * @throws {string} Error message if an error occurs or the API returns a non-zero error code.
   */
  async deleteShift({ rootState }, { shift_id }) {
    try {
      const { data } = await apiClient.post("/api/shift/delete", {
        shift_id,
        project_id: rootState.applicationState.currentProject.id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },

  /**
   * Deletes a specific version of a shift by sending a request to the backend.
   *
   * @param {Object} payload - Contains parameters for the request.
   * @param {number} payload.shift_id - The unique identifier of the shift.
   * @param {number} payload.version - The version of the shift to delete.
   * @throws {string} Error message if an error occurs or the API returns a non-zero error code.
   */
  async deleteVersion({}, { shift_id, version }) {
    try {
      const { data } = await apiClient.post(
        `/api/shift/${shift_id}/version/${version}/delete`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },

  /**
   * Updates authorization statuses for multiple authorizations by sending data to the backend.
   *
   * @param {Object} payload - Contains parameters for the request.
   * @param {Array<number>} payload.ids - Array of authorization IDs to update.
   * @param {string} payload.status - New status to set for the authorizations.
   * @param {string} payload.comment - Optional comment explaining the update.
   * @param {Object} payload.params - Additional parameters for the request.
   * @throws {string} Error message if an error occurs or the API returns a non-zero error code.
   */
  async updateShiftAuthorizations({}, { ids, status, comment, params }) {
    try {
      const { data } = await apiClient.post(
        "/api/tc_set_auth_status_for_multiple_auths",
        {
          ids,
          status,
          comment,
          ...params,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },

  /**
   * Fetches a paginated list of shifts with optional filters.
   *
   * @param {Object} payload - Contains parameters for the request.
   * @param {number} [payload.page=1] - Page number for pagination.
   * @param {number} [payload.size=10] - Number of items per page.
   * @param {Object} [payload.filter={}] - Optional filter object to refine the search.
   * @returns {Object} Data containing the list of shifts and pagination info.
   * @throws {string} Error message if an error occurs or the API returns a non-zero error code.
   */
  async getShifts(
    { rootState },
    { page = 1, size = 10, filter = {}, cancelPreviousRequests = true }
  ) {
    try {
      const { data } = await apiClient.post(
        `api/shift/get_all`,
        {
          project_id: rootState.applicationState.currentProject.id,
          page,
          size,
          filter: {
            ...filter,
            manually_created: filter.manually_created && true,
            with_pay_codes: filter.with_pay_codes && true,
            with_files: filter.with_files && true,
            adjusted: filter.adjusted && true,
            attached_pay_code: filter.attached_pay_code && true,
            pay_code: filter.pay_code && {
              id: Number(filter.pay_code),
            },
            role: filter.role && {
              id: Number(filter.role),
            },
          },
        },
        {
          cancelPreviousRequests: cancelPreviousRequests,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },

  async getAuthsByShifts({ rootState }, { page = 1, size = 10, filter = {} }) {
    const currentPaymentType =
      rootState.applicationState.currentPaymentTypeName;

    try {
      const { data } = await apiClient.post(
        `api/get_project_authorizations_by_shift`,
        {
          project_id: rootState.applicationState.currentProject.id,
          page,
          size,
          filter: {
            ...filter,
            assigned_to_me: filter.assigned_to_me && true,
            role: filter.role && Number(filter.role),
            payment_type:
              currentPaymentType === "All"
                ? filter.payment_type
                : currentPaymentType,
          },
        },
        {
          cancelPreviousRequests: true,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },

  /**
   * Fetches shift details along with authorizations by shift ID and commits the result.
   *
   * @param {Object} payload - Contains parameters for the request.
   * @param {number} payload.shift_id - The unique identifier of the shift.
   * @throws {string} Error message if an error occurs or the API returns a non-zero error code.
   */
  async getShiftWithAuth({ commit, dispatch, rootState }, { shift_id }) {
    try {
      const { data } = await apiClient.post(`/api/shift/get_with_auth`, {
        shift_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      const updatedShift = { ...data.authorizations_by_shift };

      const timeZone = rootState.applicationState.timeZone;

      const timeFields = [
        "scheduled_start",
        "scheduled_end",
        "work_start",
        "work_end",
        "lunch_start",
        "lunch_end",
        "second_lunch_start",
        "second_lunch_end",
        "actual_lunch_end",
        "actual_lunch_start",
        "actual_second_lunch_end",
        "actual_second_lunch_start",
        "actual_work_end",
        "actual_work_start",
      ];

      timeFields.forEach((field) => {
        if (updatedShift.shift[field]) {
          updatedShift.shift[field] = moment
            .utc(updatedShift.shift[field])
            .tz(timeZone);
        }
      });

      const estimatedLunches = await dispatch("getEstimatedLunches", {
        associate_number: updatedShift.associate.associate_number,
        shift_start: updatedShift.shift.scheduled_start,
        shift_end: updatedShift.shift.scheduled_end,
        lunch_waived: false,
      });

      commit("setShift", {
        ...updatedShift,
        shift: {
          ...updatedShift.shift,
          scheduled_lunch_start: estimatedLunches?.estimated_lunch_start,
          scheduled_lunch_end: estimatedLunches?.estimated_lunch_end,
          scheduled_second_lunch_end:
            estimatedLunches?.estimated_second_lunch_end,
          scheduled_second_lunch_start:
            estimatedLunches?.estimated_second_lunch_start,
        },
      });
    } catch (error) {
      throw error.message;
    }
  },

  async getShift({}, { shift_id }) {
    try {
      const { data } = await apiClient.post(`/api/shift/get`, {
        shift_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      return data.shift;
    } catch (error) {
      throw error.message;
    }
  },

  /**
   * Fetches the pay code for a specific shift.
   *
   * @param {Object} payload - Contains parameters for the request.
   * @param {number} payload.shift_id - The unique identifier of the shift.
   * @throws {string} Error message if an error occurs or the API returns a non-zero error code.
   */
  async getPayCode({}, { shift_id }) {
    try {
      const { data } = await apiClient.post("/api/shift/pay_code/get", {
        shift_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },

  /**
   * Fetches the list of pay codes for a specific shift and commits them to the Vuex store.
   *
   * @param {Object} payload - Contains parameters for the request.
   * @param {number} payload.shift_id - The unique identifier of the shift.
   * @throws {string} Error message if an error occurs or the API returns a non-zero error code.
   */
  async getShiftPayCodes({ commit }, { shift_id }) {
    try {
      const { data } = await apiClient.post("/api/shift/pay_codes/get", {
        shift_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      commit("setShiftPayCodes", data.pay_codes);
    } catch (error) {
      throw error.message;
    }
  },

  /**
   * Fetches calculation details for a specific shift and commits them to the Vuex store.
   *
   * @param {Object} payload - Contains parameters for the request.
   * @param {number} payload.shift_id - The unique identifier of the shift.
   * @throws {string} Error message if an error occurs or the API returns a non-zero error code.
   */
  async getShiftCalculations({ commit }, { shift_id }) {
    try {
      const { data } = await apiClient.post("/api/shift/calculations/get", {
        shift_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      commit("setShiftCalculations", data.calculations);
    } catch (error) {
      throw error.message;
    }
  },

  async getShiftPayments({ commit }, { shift_id }) {
    try {
      const { data } = await apiClient.post("/api/shift/payment/get", {
        shift_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      commit("setShiftPayments", data.payments);
    } catch (error) {
      throw error.message;
    }
  },

  async getShiftActions({ commit }, { shift_id }) {
    try {
      const { data } = await apiClient.post("/api/shift/action/get_all", {
        shift_ids: Array.isArray(shift_id) ? shift_id : [shift_id],
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ?? "An error occurred while loading the list of locations."
          } Please try again later`
        );
      }

      if (data.shift_actions && data.shift_actions.length) {
        const allRecords = data.shift_actions.reduce(
          (accumulator, currentShift) => {
            if (currentShift.records && currentShift.records.length > 0) {
              return accumulator.concat(currentShift.records);
            }
            return accumulator;
          },
          []
        );

        commit("setShiftActions", allRecords);
      } else {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },

  async createPayCode({ rootState }, { dates, associates, pay_code }) {
    try {
      const { data } = await apiClient.post("/api/shift/pay_code/create", {
        project_id: rootState.applicationState.currentProject.id,
        pay_code_shift_form: {
          dates,
          associates: associates.map((item) => ({ associate_number: item })),
          pay_code,
        },
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ?? "An error occurred while creating pay code."
          } Please try again later`
        );
      }
    } catch (error) {
      throw error.message;
    }
  },

  async attachPayCode({ rootState }, { pay_code, shift_ids }) {
    try {
      const { data } = await apiClient.post("/api/shift/pay_code/attach", {
        project_id: rootState.applicationState.currentProject.id,
        author_id: rootState.applicationState.associate.id,
        pay_code,
        shift_ids,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ?? "An error occurred while creating pay code."
          } Please try again later`
        );
      }
    } catch (error) {
      throw error.message;
    }
  },

  async updatePayCode({ rootState }, { pay_code, shift_id }) {
    try {
      const { data } = await apiClient.post("/api/shift/pay_code/update", {
        project_id: rootState.applicationState.currentProject.id,
        pay_code,
        shift_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ?? "An error occurred while updating pay code."
          } Please try again later`
        );
      }
    } catch (error) {
      throw error.message;
    }
  },

  async postSignShift({}, { shift_id, version_id, signature_id }) {
    try {
      const { data } = await apiClient.post(
        `/api/shift/${shift_id}/version/${version_id}/sign`,
        {
          signature: {
            id: signature_id,
          },
          create_context: true,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ?? "An error occurred while signing shift."
          } Please try again later`
        );
      }
    } catch (error) {
      throw error.message;
    }
  },

  updateShiftField({ commit }, payload) {
    commit("UPDATE_SHIFT_FIELD", payload);
  },

  setShiftFields({ commit }, payload) {
    commit("SET_SHIFT_FIELDS", payload);
  },

  async getEstimatedLunches(
    { rootState },
    { associate_number, shift_start, shift_end, lunch_waived }
  ) {
    const timeZone = rootState.applicationState.timeZone;

    try {
      const { data } = await apiClient.post(`/api/get_estimated_lunch_time`, {
        shift_start,
        associate_number,
        shift_end,
        lunch_waived,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      return {
        estimated_lunch_end: data.estimated_lunch_end
          ? moment.utc(data.estimated_lunch_end).tz(timeZone)
          : undefined,
        estimated_lunch_start: data.estimated_lunch_start
          ? moment.utc(data.estimated_lunch_start).tz(timeZone)
          : undefined,
        estimated_second_lunch_end: data.estimated_second_lunch_end
          ? moment.utc(data.estimated_second_lunch_end).tz(timeZone)
          : undefined,
        estimated_second_lunch_start: data.estimated_second_lunch_start
          ? moment.utc(data.estimated_second_lunch_start).tz(timeZone)
          : undefined,
      };
    } catch (error) {
      throw error.message;
    }
  },

  async postAuthorizationCheck({ rootState, state }, { associate_number }) {
    try {
      const { data } = await apiClient.post(`/api/shift/authorization/check`, {
        project_id: rootState.applicationState.currentProject.id,
        associate_number,
        shift: state.shiftForm,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      return data.authorizations;
    } catch (error) {
      throw error.message;
    }
  },

  async postShiftConflict({ rootState, state }, { associate_number }) {
    try {
      const { data } = await apiClient.post(`/api/shift/conflict/check`, {
        project_id: rootState.applicationState.currentProject.id,
        shift_id: state.shiftForm.id,
        start: moment(state.shiftForm.work_start).utc().toISOString(),
        end: moment(state.shiftForm.work_end).utc().toISOString(),
        associate_number,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      return data.result;
    } catch (error) {
      throw error.message;
    }
  },

  async updateShift({ rootState }, { auto_sign, shift }) {
    try {
      const { data } = await apiClient.post(`/api/shift/adjust_time`, {
        project_id: rootState.applicationState.currentProject.id,
        position: {
          latitude: 0,
          longitude: 0,
          altitude: 0,
        },
        auto_sign,
        shift,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ?? "An error occurred while adjusting the shift's times."
          } Please try again later`
        );
      }
    } catch (error) {
      throw error.message;
    }
  },

  async createShift({ rootState }, { associate_number, auto_sign, shift }) {
    try {
      const { data } = await apiClient.post(
        `/api/shift/manually_entered/create`,
        {
          project_id: rootState.applicationState.currentProject.id,
          associate_number,
          position: {
            latitude: 0,
            longitude: 0,
            altitude: 0,
          },
          completed_authorizations: [],
          auto_sign,
          shift,
        }
      );

      if (
        data.error_code &&
        data.error_code !== "0" &&
        data.error_code !== "2"
      ) {
        throw new Error(
          `${
            data.msg ?? "An error occurred while saving the shift"
          } Please try again later`
        );
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },

  async downloadReportShiftTimeCards({}, { shift_id }) {
    try {
      const { data } = await apiClient.get(
        `/api/report/shift/${shift_id}/shift_card/download`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      return data.url;
    } catch (error) {
      throw error.message;
    }
  },

  async sendReportShiftTimeCards({}, { shift_id, email }) {
    try {
      const { data } = await apiClient.get(
        `/api/report/shift/${shift_id}/shift_card/send_email/${email}`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },

  async markShiftsAsPaid({}, payload) {
    try {
      const { data } = await apiClient.post("/api/shift/mark_paid", {
        ...payload,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ?? "An error occurred while marking shifts as paid."
          } Please try again later`
        );
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },

  async updateShiftAttachments({ commit, state }, { files, shift_id }) {
    try {
      const { data } = await apiClient.put(
        `/api/shift/${shift_id}/attachments`,
        {
          files,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ?? "An error occurred while upload shift's attachments."
          } Please try again later`
        );
      }

      const updatedShift = {
        ...state.shift,
        shift: { ...state.shift.shift, files: data.attached_files },
      };

      commit("setShift", updatedShift);
    } catch (error) {
      throw error.message;
    }
  },

  async getShiftRetrieve({}, { shift_id }) {
    try {
      const { data } = await apiClient.get(
        `/api/shift/attach_data/${shift_id}`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },

  async postShiftMove({}, payload) {
    try {
      const { data } = await apiClient.put(
        `/api/shift/move_to_another_associate`,
        {
          ...payload,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },
};
