import apiClient from "@/services/api";

export default {
  async getPayments(
    { commit, rootState },
    { page = 1, size = 50, filter = {} }
  ) {
    try {
      const { data } = await apiClient.post(
        "/api/payment_requests/project/get_all",
        {
          project_id: rootState.applicationState.currentProject.id,
          page,
          size,
          filter: {
            ...filter,
            history: filter.history && true,
          },
        },
        {
          cancelPreviousRequests: true,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ??
            "An error occurred while loading the payment requests. Please try again later"
          } Please try again later`
        );
      }

      commit("setPayments", data);
    } catch (error) {
      throw error.message;
    }
  },

  async getPayment({ commit }, { payment_id }) {
    try {
      const { data } = await apiClient.post(
        `api/payment_requests/${payment_id}/get`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ??
            "An error occurred while loading the payment request. Please try again later"
          } Please try again later`
        );
      }

      commit("setPayment", data);
    } catch (error) {
      throw error.message;
    }
  },

  async updatePaymentAttachments({ commit, state }, { files, payment_id }) {
    try {
      const { data } = await apiClient.post(
        `/api/payment_request/${payment_id}/files`,
        {
          file_ids: [...files],
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ?? "An error occurred while upload payment's attachments."
          } Please try again later`
        );
      }

      const updatedPayment = {
        ...state.payment,
        files: data.files,
      };

      commit("setPayment", updatedPayment);
    } catch (error) {
      throw error.message;
    }
  },

  async approvePaymentRequest(_, { request_id }) {
    try {
      const { data } = await apiClient.post(
        `api/payment_requests/${request_id}/set_approve`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },

  async approveShiftPayments(_, { request_id, shift_payment_ids }) {
    try {
      const { data } = await apiClient.post(
        `api/payment_requests/${request_id}/shift_payments/approve`,
        { shift_payment_ids }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },

  async declineShiftPayments(_, { request_id, shift_payment_ids }) {
    try {
      const { data } = await apiClient.post(
        `api/payment_requests/${request_id}/shift_payments/decline`,
        { shift_payment_ids }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },

  async getPaymentSystems({ commit, rootState }) {
    const currentProjectId = rootState.applicationState.currentProject.id;

    try {
      const { data } = await apiClient.post(
        "/api/get_project_payment_systems",
        {
          project_id: currentProjectId,
        }
      );
      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      const updatedSystems = data.payment_systems.filter((item) => {
        const projectAssociation = item.payment_system_project_association.find(
          (project) => project.project.id === currentProjectId
        );

        return (
          projectAssociation &&
          projectAssociation.configs.enabled === true &&
          projectAssociation.configs.activated === true
        );
      });

      commit("setSystems", updatedSystems);
    } catch (error) {
      throw error.message;
    }
  },

  async getPaymentBalance({}, { payment_system_id }) {
    try {
      const { data } = await apiClient.get(
        `/api/payment_systems/${payment_system_id}/balance`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg}. Please try again later`);
      }

      return data.balance;
    } catch (error) {
      throw error.message;
    }
  },

  async downloadAgreement(_, { payment_id }) {
    try {
      const { data } = await apiClient.get(
        `/api/payments_requests/${payment_id}/agreement/download`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg}. Please try again later`);
      }

      return data.url;
    } catch (error) {
      throw error.message;
    }
  },

  async sendAgreement(_, { payment_id, email }) {
    try {
      const { data } = await apiClient.get(
        `/api/payments_requests/${payment_id}/agreement/send_email/${email}`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg}. Please try again later`);
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },
};
