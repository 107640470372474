<template>
  <a-modal
    width="600px"
    title="Reason of Deny Authorization"
    @cancel="closeModal"
    :visible="visible"
    :destroy-on-close="true"
  >
    <div class="flex flex-column gap-3">
      <a-select
        v-model="selectedDeclineReason"
        placeholder="Select Reason"
        style="width: 100%"
        @change="handleReasonChange"
        :dropdownMatchSelectWidth="false"
        :dropdownStyle="{ width: '500px' }"
      >
        <a-select-option
          v-for="(text, key) in processedOptions"
          :key="key"
          :value="key"
          class="custom-dropdown-option"
        >
          {{ text }}
        </a-select-option>
        <a-select-option value="custom" class="custom-dropdown-option">
          Custom Reason
        </a-select-option>
      </a-select>
      <a-textarea
        v-if="isCustomReason"
        v-model="ownReason"
        placeholder="Enter custom reason"
        style="margin-top: 15px"
        :auto-size="{ minRows: 2, maxRows: 5 }"
      />
      <div class="grid">
        <div class="col-6">
          <div class="flex flex-column gap-2">
            <div class="flex gap-2">
              <a-switch v-model="ownParams.readyForPayment" />
              <span>Shift Ready For Payment</span>
            </div>
            <div class="flex gap-2">
              <a-switch v-model="ownParams.withWriteUp" />
              <span>Send Write-Up</span>
            </div>
            <div class="flex gap-2">
              <a-switch v-model="ownParams.shiftActionAutocomplete" />
              <span>Shift Action Autocomplete</span>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="flex flex-column gap-2">
            <div class="flex gap-2">
              <a-switch v-model="ownParams.terminated" />
              <span>Terminate</span>
            </div>
            <div class="flex gap-4" v-if="!ownParams.terminated">
              <div class="flex gap-2">
                <a-switch v-model="setSuspend" />
                <span>Suspend</span>
              </div>
              <div class="flex gap-2" v-if="setSuspend">
                <a-input-number
                  size="small"
                  :min="1"
                  v-model="ownParams.suspend"
                />
                <span>Days</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template slot="footer">
      <div class="flex justify-content-between">
        <a-button @click="closeModal"> Cancel </a-button>
        <div class="flex justify-content-end">
          <a-button type="danger" @click="confirm(false)"> Deny </a-button>
          <a-button v-if="isAdjusted" type="danger" @click="confirm(true)">
            Deny & Adjust
          </a-button>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script>
import {
  Select,
  Input,
  InputNumber,
  Switch,
  Button,
  Modal,
} from "ant-design-vue";
import Util from "@/util";

const initialParams = {
  readyForPayment: false,
  withWriteUp: false,
  suspend: 0,
  terminated: false,
  shiftActionAutocomplete: false,
};

export default {
  components: {
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-input": Input,
    "a-input-number": InputNumber,
    "a-textarea": Input.TextArea,
    "a-switch": Switch,
    "a-button": Button,
  },
  props: {
    declineReasons: {
      type: Object,
      required: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    isAdjusted: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
    },
    reason: {
      type: String,
      default: "",
    },
  },
  emits: ["confirmDeny"],
  data() {
    return {
      selectedDeclineReason: undefined,
      ownReason: "",
      constants: null,

      setSuspend: false,

      ownParams: {
        ...initialParams,
      },

      features: this.$store.state.applicationState.currentProject.features,
    };
  },
  watch: {
    ownReason: {
      handler(newValue) {
        this.$emit("update:reason", newValue);
      },
    },
    selectedDeclineReason: {
      handler(newValue) {
        if (newValue === "custom") {
          this.ownReason = "";
        } else {
          this.ownReason = this.processedOptions[newValue];
        }
      },
    },
    setSuspend() {
      if (!this.setSuspend) {
        this.ownParams.suspend = 0;
      } else if (this.setSuspend && this.ownParams.suspend == 0) {
        this.ownParams.suspend = 1;
      }
    },
    ownParams: {
      handler() {
        if (this.ownParams.terminated) {
          this.setSuspend = false;
          this.ownParams.suspend = 0;
        }

        this.$emit("update:ownParams", this.ownParams);
      },
      deep: true,
    },
  },
  computed: {
    hasAutomaticFeature() {
      return this.features.find((item) => item.name === "auto_authorization")
        ?.is_activated;
    },
    isCustomReason() {
      return this.selectedDeclineReason === "custom";
    },

    minShiftDurationValue() {
      return (
        this.$store.state.applicationState.timeConstants.min_work_duration +
        this.$store.state.applicationState.timeConstants.lunch_duration
      );
    },
    minShiftDuration() {
      return Util.formatMinutesToHoursAndMinutesDuration(
        this.minShiftDurationValue
      );
    },
    almostMinShiftDuration() {
      return Util.formatMinutesToHoursAndMinutesDuration(
        this.minShiftDurationValue - 1
      );
    },

    longShiftDurationValue() {
      return (
        this.$store.state.applicationState.timeConstants.long_work_duration +
        this.$store.state.applicationState.timeConstants.lunch_duration
      );
    },
    longShiftDuration() {
      return Util.formatMinutesToHoursAndMinutesDuration(
        this.$store.state.applicationState.timeConstants.long_work_duration +
          this.$store.state.applicationState.timeConstants.lunch_duration
      );
    },

    almostLongShiftDuration() {
      return Util.formatMinutesToHoursAndMinutesDuration(
        this.longShiftDurationValue - 1
      );
    },

    firstLunchTime() {
      return Util.formatMinutesToHoursAndMinutesDuration(
        this.$store.state.applicationState.timeConstants.first_lunch_time
      );
    },

    longWorkDurationHours() {
      return Util.formatMinutesToHoursAndMinutesDuration(
        this.$store.state.applicationState.timeConstants.long_work_duration
      );
    },
    lunchDuration() {
      return Util.formatMinutesToHoursAndMinutesDuration(
        this.$store.state.applicationState.timeConstants.lunch_duration
      );
    },
    secondLunchDuration() {
      return Util.formatMinutesToHoursAndMinutesDuration(
        this.$store.state.applicationState.timeConstants.second_lunch_duration
      );
    },

    processedOptions() {
      return (
        this.declineReasons ?? {
          no_lunch_authorization: `If you work a ${this.minShiftDuration} shift or more, you must take at
          least ${this.lunchDuration} unpaid meal break. You may waive the meal break if
          you work ${this.almostMinShiftDuration} or less. You do not have authorization to work
          over ${this.minShiftDuration} and not to take the required meal break. Continued non-compliance may result in
          removal of all your shifts from the schedule and / or disciplinary actions.`,
          no_second_lunch_authorization: `If you work a ${this.longShiftDuration} shift or more, you must take
          two at least ${this.lunchDuration} unpaid meal breaks. You may waive the second meal break if you work
          ${this.almostLongShiftDuration} or less. You do not have authorization to work over ${this.longShiftDuration}
          and not to take the required meal breaks. Continued non-compliance may result in removal of all your shifts
          from the schedule and / or disciplinary actions.`,
          late_end_authorization: `You must end your shifts on time. Late end of shift may result in overtime.
          Stick to your schedule. Do not work shifts with late end shift unless you have authorization.
          Continued non-compliance may result in removal of all your shifts from the schedule
          and / or disciplinary actions.`,
          early_start_authorization: `You must start your shifts on time. Early start of shift may result in overtime.
          Stick to your schedule. Do not sign shifts with early start shift unless you have authorization.
          Continued non-compliance can result in disciplinary action.`,
          early_lunch_end_authorization: `You must take your meal break for at least ${this.lunchDuration}. You do not
          have authorization to work with a meal break shorter than ${this.lunchDuration}. Continued non-compliance
         may result in removal of all your shifts from the schedule and / or disciplinary actions.`,
          early_second_lunch_end_authorization: `You must take your second meal break for at least ${this.lunchDuration}.
          You do not have authorization to work with a meal break shorter than ${this.lunchDuration}. Continued
          non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.`,
          no_actual_time_authorization:
            "Adding shifts without tracked worked time constitutes a breach of company policy and can lead to payroll errors. This action disrupts accurate payroll processing and compliance with labor laws, potentially leading to financial discrepancies. ",
          overtime_authorization: `You does not have authorization to work overtime. Continued non-compliance may result in removal of all their shifts from the schedule and / or disciplinary actions.`,
          outside_work_area_authorization: `You should only work and track activities in the app in the assigned work
          area. Continued non-compliance may result in removal of all your shifts from the schedule
          and / or disciplinary actions.`,
          absence_on_shift_authorization: `You does not have authorization to skip your scheduled shifts. Continued non-compliance may result in removal of all their shifts from the schedule and / or disciplinary actions.`,
          late_start_authorization:
            "You shouldn't sign shifts with late start unless you have authorization. Continued non-compliance may result in the removal of all your shifts from the schedule and / or disciplinary actions.",
          early_end_authorization:
            "You shouldn't sign shifts with early end unless you have authorization. Continued non-compliance may result in the removal of all your shifts from the schedule and / or disciplinary actions.",
          first_lunch_period_authorization: `If you work without a break ${this.firstLunchTime} or more, you must take an unpaid
          meal break of at least ${this.lunchDuration}. You may waive the meal break if you work
          ${this.minShiftDuration} or less. Continued non-compliance may result in removal of all of your shifts from
          your schedule and/or disciplinary action.`,
          signed_actual_time_discrepancy:
            "Mismatch between your signed and tracked shift times are violates company policy. Continued non-compliance may result in removal of all their shifts from the schedule and / or disciplinary actions.",
          no_rest_break_authorization:
            "Not take scheduled rest breaks are violates company policy. Continued non-compliance may result in removal of all their shifts from the schedule and / or disciplinary actions.",
          associate_not_recognized_authorization: `Only you should use your Associate to track the shift. Using your Associate by someone else violates company policy and may result in the removal of all your shifts from the schedule and/or disciplinary actions.`,
          uncompleted_required_tasks_authorization:
            "Not completing critical tasks violates company policy. Continued non-compliance may result in removal of all their shifts from the schedule and / or disciplinary actions.",
          attachments_missing_authorization:
            "Not attaching required files violates company policy. Continued non-compliance may result in removal of all their shifts from the schedule and / or disciplinary actions.",
          unscheduled_shift_authorization:
            "The submission lacks the required documentation or information, which violates company policy. Please ensure all necessary forms and details are provided for future submissions. Continued non-compliance may result in the removal of all future shifts from the schedule and/or disciplinary actions.",
        }
      );
    },
  },
  methods: {
    closeModal() {
      this.$emit("update:visible", false);
      this.$emit("update:params", initialParams);
      this.$emit("update:reason", "");
    },

    confirm(checked) {
      const createContent = (h) => {
        return h("div", [
          h(
            "p",
            "The system automatically applies disciplinary actions based on the number of declined authorizations by an associate. Below are the steps taken for each violation:"
          ),
          h(
            "ul",
            [
              {
                level: "1st Violation",
                actions: ["Notification to Department Managers"],
              },
              { level: "2nd Violation", actions: ["Write-Up Created"] },
              { level: "3rd Violation", actions: ["Write-Up Created"] },
              {
                level: "4th Violation",
                actions: ["Write-Up Created and 1-Day Suspension"],
              },
              {
                level: "5th Violation",
                actions: ["Write-Up Created and 2-Day Suspension"],
              },
              {
                level: "6th Violation",
                actions: ["Write-Up Created and Indefinite Suspension"],
              },
            ].map((item) =>
              h("li", [
                h("strong", item.level),
                h(
                  "ul",
                  item.actions.map((action) => h("li", action))
                ),
              ])
            )
          ),
        ]);
      };

      if (this.hasAutomaticFeature) {
        Modal.confirm({
          title: "Rules of the Automatic Disciplinary Action System",
          width: 600,
          centered: true,
          content: (h) => createContent(h),
          onOk: () => {
            this.$emit("update:visible", false);
            this.$emit("confirmDeny", checked);
          },
          onCancel: () => {},
        });
      } else {
        this.$emit("update:visible", false);
        this.$emit("confirmDeny", checked);
      }
    },

    handleReasonChange(value) {
      if (value !== "custom") {
        this.ownReason = this.processedOptions[value];
      } else {
        this.ownReason = "";
      }
    },

    setAuths(selectedAuths) {
      const previousReasonKey = this.selectedDeclineReason;

      if (selectedAuths.length === 1) {
        const reasonKey = selectedAuths[0].type;

        if (this.processedOptions.hasOwnProperty(reasonKey)) {
          this.selectedDeclineReason = reasonKey;
        } else {
          this.selectedDeclineReason = "custom";
        }
      } else {
        this.selectedDeclineReason = "custom";
      }

      if (previousReasonKey === this.selectedDeclineReason) {
        this.$emit("reason", this.ownReason);
      }
    },
  },
};
</script>

<style>
.custom-dropdown-option {
  white-space: normal;
  word-wrap: break-word;
  min-height: 20px;
  line-height: 1.5;
  padding: 5px 12px;
}
</style>
