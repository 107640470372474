<template>
  <a-modal
    title="Filters"
    width="600px"
    :visible="visible"
    @cancel="cancel"
    :destroy-on-close="true"
  >
    <div class="grid">
      <b class="col-4">Associates</b>
      <search-associates
        class="col-8"
        :is-associate-id="true"
        :multiply="true"
        :value.sync="filters.associates"
      />

      <b class="col-4">Job Classification</b>
      <a-select
        class="col-8"
        v-model="filters.role"
        placeholder="Job Classification"
        mode="multiple"
        :allow-clear="true"
      >
        <a-select-option v-for="role in roles" :key="role.id" :value="role.id">
          {{ role.name }}
        </a-select-option>
      </a-select>

      <b class="col-4">Agency</b>
      <a-select
        class="col-8"
        v-model="filters.agency"
        placeholder="Agency"
        mode="multiple"
        :allow-clear="true"
      >
        <a-select-option
          v-for="agency in filteredAgencies"
          :key="agency.id"
          :value="agency.id"
        >
          {{ agency.name }}
        </a-select-option>
      </a-select>

      <b class="col-4">Department</b>
      <a-select
        class="col-8"
        v-model="filters.department"
        placeholder="Department"
        mode="multiple"
        :allow-clear="true"
      >
        <a-select-option
          v-for="department in departments"
          :key="department.id"
          :value="department.id"
        >
          {{ department.name }}
        </a-select-option>
      </a-select>

      <b class="col-4">Status</b>
      <a-select
        class="col-8"
        v-model="filters.status"
        placeholder="Status"
        mode="multiple"
        :allow-clear="true"
      >
        <a-select-option
          v-for="status in statuses"
          :key="status"
          :value="status"
        >
          {{ capitalize(status) }}
        </a-select-option>
      </a-select>

      <template v-if="isShowPaymentType">
        <b class="col-4">Payment Type</b>
        <a-select
          class="col-8"
          v-model="filters.payment_type"
          :allow-clear="true"
          :dropdown-match-select-width="false"
          placeholder="Payment Type"
        >
          <a-select-option
            v-for="type in paymentTypes"
            :key="type.id"
            :value="type.name"
          >
            {{ type.name }}
          </a-select-option>
        </a-select></template
      >

      <div class="flex gap-2 flex-wrap mt-2">
        <a-button
          @click="toggleFilter('unverified')"
          :type="filters.unverified ? 'primary' : 'default'"
        >
          Unverified
        </a-button>
      </div>
    </div>
    <template slot="footer">
      <div class="flex justify-content-end">
        <a-button type="danger" :disabled="isEmptyFilters" @click="reset">
          Reset </a-button
        ><a-button type="primary" @click="apply"> Apply </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import {
  Modal,
  Button,
  Select,
  Checkbox,
  Switch,
  DatePicker,
} from "ant-design-vue";
import SearchAssociates from "../../../components/search-associates.vue";
import util from "@/util";

const filtersBaseState = Object.freeze({
  associates: [],
  status: undefined,
  role: [],
  unverified: false,
  agency: [],
  department: [],
  payment_type: undefined,
});

export default {
  components: {
    "a-modal": Modal,
    "a-button": Button,
    "a-select": Select,
    "a-checkbox": Checkbox,
    "a-select-option": Select.Option,
    "a-switch": Switch,
    "a-date-picker": DatePicker,
    "search-associates": SearchAssociates,
  },
  data() {
    return {
      filters: { ...filtersBaseState },
      roles: this.$store.state.applicationState.projectRoles,
      paymentTypes: this.$store.state.applicationState.paymentTypes,
      legalEntities: this.$store.state.applicationState.projectLegalEntities,
      departments: this.$store.state.applicationState.projectDepartments,
      statuses: ["active", "suspended", "terminated", "disabled"],
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    currentFilters: {
      type: Object,
      required: true,
    },
  },
  watch: {
    currentFilters: {
      handler(newValue) {
        this.filters = {
          ...newValue,
          unverified: newValue.unverified ? true : undefined,
          role: newValue.role ? newValue.role.map(Number) : [],
          department: newValue.department
            ? newValue.department.map(Number)
            : [],
          agency: newValue.agency ? newValue.agency.map(Number) : [],
        };
      },
      immediate: true,
      deep: true,
    },
    "$store.state.applicationState.currentPaymentType": {
      handler() {
        if (this.filters.payment_type && !this.isShowPaymentType) {
          this.filters.payment_type = undefined;
          this.$router.replace({ query: this.filters }).catch(() => {});
        }
      },
      immediate: true,
    },
  },
  computed: {
    isEmptyFilters() {
      return JSON.stringify(filtersBaseState) == JSON.stringify(this.filters);
    },

    isShowPaymentType() {
      return (
        this.$store.state.applicationState.currentPaymentTypeName === "All"
      );
    },

    filteredAgencies() {
      return this.legalEntities.filter((item) => item.type === "agency");
    },
  },

  methods: {
    reset() {
      if (this.isEmptyFilters) {
        this.cancel();
      } else {
        this.filters = { ...filtersBaseState };
        this.$router.replace({ query: {} }).catch(() => {});
      }
    },
    cancel() {
      this.$emit("update:visible", false);
    },
    apply() {
      this.cancel();
      this.$router.replace({ query: this.filters }).catch(() => {});
    },
    toggleFilter(type) {
      this.filters[type] = this.filters[type] ? undefined : true;
    },
    capitalize: util.capitalize,
  },
};
</script>
