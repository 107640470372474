<template>
  <div class="grid">
    <div class="col flex flex-column gap-4">
      <div>
        <h3>Job</h3>
        <a-descriptions :column="1" size="small" class="col-fixed p-0">
          <a-descriptions-item label="Job Classification">
            {{ associate?.role?.name }}
          </a-descriptions-item>
          <a-descriptions-item label="Schedule Type">
            {{ getScheduledTypeName(associate.schedule_type) }}
          </a-descriptions-item>
          <a-descriptions-item label="License">
            {{ associate?.license?.number ?? "No license" }}
          </a-descriptions-item>
          <a-descriptions-item
            v-if="associate?.license"
            label="License Expiration Date"
          >
            {{ formatDateString(associate?.license?.expiration_date) }}
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <div>
        <h3>Company</h3>
        <a-descriptions :column="1" size="small" class="col-fixed p-0">
          <a-descriptions-item label="Shift Actions Autocomplete">
            {{ associate.automatic_completing_actions ? "Yes" : "Manually" }}
          </a-descriptions-item>
          <a-descriptions-item label="Auto Approve Payment Request">
            {{ associate.payment_auto_approve ? "Yes" : "No" }}
          </a-descriptions-item>
        </a-descriptions>
      </div>
    </div>
    <div class="col flex flex-column gap-4">
      <activity-info :associate-id="associate.id" />
      <suspension-info
        v-if="isActiveAssociate"
        :suspension="associate.suspension"
        :associate-number="associate.associate_number"
      />
    </div>
  </div>
</template>

<script>
import { SCHEDULED_TYPE } from "@/store/modules/profile/constants";
import { Button, Descriptions } from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";
import ProfileActivityInfo from "./profile-activity-info.vue";
import ProfileSuspensionInfo from "./profile-suspension-info.vue";
import moment from "moment-timezone";

const { mapState: profileState } = createNamespacedHelpers("profile");

export default {
  components: {
    "a-button": Button,
    "a-descriptions": Descriptions,
    "a-descriptions-item": Descriptions.Item,
    "activity-info": ProfileActivityInfo,
    "suspension-info": ProfileSuspensionInfo,
  },
  computed: {
    ...profileState({
      associate: (state) => state.associate,
    }),

    isActiveAssociate() {
      return this.associate.is_active;
    },
  },
  methods: {
    getScheduledTypeName(type) {
      return SCHEDULED_TYPE[type];
    },
    formatDateString(dateString) {
      return moment(dateString).utc().format("MM/DD/YYYY");
    },
  },
};
</script>

<style scoped>
.ant-card {
  height: fit-content;
  border-radius: 10px;
}

.ant-descriptions {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

::v-deep .ant-descriptions-item-label {
  font-weight: bold;
  border: none;
  background-color: white;
}

::v-deep .ant-descriptions-row {
  border: none;
}

::v-deep .ant-descriptions-item-content {
  overflow-x: auto;
}

::v-deep .ant-descriptions-view {
  padding: 16px;
}

::v-deep .ant-descriptions-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
