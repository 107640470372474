<template>
  <a-card title="Management Scope" v-if="isVisible">
    <a-descriptions :column="1" size="small" class="p-0">
      <a-descriptions-item label="Departments">
        {{ display("departments") }}
      </a-descriptions-item>
      <a-descriptions-item label="Legal Entity">
        {{ display("legal_entity") }}
      </a-descriptions-item>
    </a-descriptions>
  </a-card>
</template>

<script>
import { Card, Descriptions } from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";

const { mapState: profileState } = createNamespacedHelpers("profile");

export default {
  components: {
    "a-descriptions": Descriptions,
    "a-descriptions-item": Descriptions.Item,
    "a-card": Card,
  },
  computed: {
    ...profileState({
      associate: (state) => state.associate,
    }),

    isVisible() {
      return Object.values(this.groupedByType).length > 0;
    },

    groupedByType() {
      return this.associate.manager_scopes.reduce((acc, item) => {
        const { object_type } = item;

        if (!acc[object_type]) {
          acc[object_type] = [];
        }

        acc[object_type].push(item);

        return acc;
      }, {});
    },
  },

  methods: {
    display(key) {
      const departments = this.groupedByType?.[key] || [];
      return departments.map((item) => item.name).join(",") || " - ";
    },
  },
};
</script>

<style scoped>
.ant-card {
  height: fit-content;
  border-radius: 10px;
}

::v-deep .ant-card-body {
  padding: 0;
}

::v-deep .ant-card-head {
  padding: 0 16px;
}

::v-deep .ant-descriptions-item-label {
  font-weight: bold;
  border: none;
  background-color: white;
}

::v-deep .ant-descriptions-row {
  border: none;
}

::v-deep .ant-descriptions-item-content {
  overflow-x: auto;
}

::v-deep .ant-descriptions-view {
  padding: 16px;
}

::v-deep .ant-descriptions-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
