<template>
  <div>
    <documents-send
      :visible.sync="visibleSendDocumentsModal"
      :loading="loadingSendDocument"
      @send="sendDocuments"
      @close="closeSendDocumentsModal"
    />

    <div class="flex flex-column gap-4">
      <div class="flex justify-content-between align-items-center">
        <h3 class="m-0">Signed Documents</h3>
        <div class="flex gap-2">
          <a-button
            icon="download"
            type="primary"
            @click="downloadAllDocuments()"
          >
            Download All
          </a-button>
          <a-button icon="mail" @click="openSendDocumentsModal()">
            Send All To Email
          </a-button>
        </div>
      </div>

      <a-table
        size="small"
        :style="{ whiteSpace: 'pre' }"
        :columns="columns"
        :data-source="documents"
        :loading="loading"
        :pagination="pagination"
        :rowKey="(record) => record.id"
        @change="handleTableChange"
      >
        <span slot="date" slot-scope="text, record">
          {{ formatDateString(record.signature.signed_date) }}
        </span>
        <span slot="actions" slot-scope="text, record">
          <div class="flex gap-2 justify-content-center">
            <a-button
              icon="download"
              type="primary"
              @click="downloadDocument(record.id, record.current_version)"
            >
              Download
            </a-button>
            <a-button icon="mail" @click="openSendDocumentsModal(record.id)">
              Send To Email
            </a-button>
          </div>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import { Button, notification, Table } from "ant-design-vue";
import moment from "moment-timezone";
import { createNamespacedHelpers } from "vuex";
import DocumentSendModal from "@/components/send-modal.vue";

const { mapActions: profileActions, mapState: profileState } =
  createNamespacedHelpers("profile");
const { mapActions: documentActions } = createNamespacedHelpers("document");

export default {
  components: {
    "a-table": Table,
    "a-button": Button,

    "documents-send": DocumentSendModal,
  },
  data() {
    return {
      visibleSendDocumentsModal: false,

      sendDocumentId: undefined,

      pagination: {
        showSizeChanger: true,
        pageSizeOptions: ["5", "10", "20", "50"],
        pageSize: 10,
        total: 0,
        current: 1,
      },

      documents: [],

      columns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          width: 80,
          sorter: (a, b) => a.id - b.id,
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: (a, b) =>
            a.name == null
              ? -1
              : b.name == null
              ? 1
              : a.name.localeCompare(b.name),
        },
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          align: "center",
          width: 150,
          scopedSlots: { customRender: "date" },
          sorter: (a, b) =>
            a.signature.signed_date == null
              ? -1
              : b.signature.signed_date == null
              ? 1
              : moment(a.signature.signed_date).valueOf() -
                moment(b.signature.signed_date).valueOf(),
        },
        {
          title: "Actions",
          dataIndex: "actions",
          key: "actions",
          align: "center",
          width: 300,
          scopedSlots: { customRender: "actions" },
        },
      ],

      loadingSendDocument: false,
      loading: false,
    };
  },
  computed: {
    ...profileState({
      associate: (state) => state.associate,
      signedDocuments: (state) => state.signedDocuments,
    }),
  },
  watch: {
    signedDocuments: {
      handler(newVal) {
        this.pagination.total = newVal.total_count;
        this.documents = newVal.items;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...profileActions(["getSignedDocuments"]),

    ...documentActions([
      "downloadAssociateDocument",
      "downloadAllAssociateDocuments",
      "sendAssociateDocument",
      "sendAllAssociateDocument",
    ]),

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    formatDateString(dateString) {
      return moment(dateString).format("MM/DD/YYYY");
    },

    openSendDocumentsModal(id) {
      this.visibleSendDocumentsModal = true;
      if (id) this.sendDocumentId = id;
    },

    closeSendDocumentsModal() {
      this.visibleSendDocumentsModal = false;
      this.sendDocumentId = undefined;
    },

    handleTableChange(pagination) {
      const isPaginationChanged =
        pagination.current !== this.pagination.current ||
        pagination.pageSize !== this.pagination.pageSize;

      if (!isPaginationChanged) {
        return;
      }

      this.pagination = { ...pagination };

      this.fetchDocuments();
    },

    async downloadDocument(document_id, version_number) {
      try {
        const payload = {
          document_id,
          version_number,
          associate_id: this.associate.id,
        };

        const file_url = await this.downloadAssociateDocument(payload);

        window.open(file_url, "_blank");
      } catch (error) {
        this.showNotification("error", "Error", error);
      }
    },

    async downloadAllDocuments() {
      try {
        const payload = {
          associate_id: this.associate.id,
        };

        const file_url = await this.downloadAllAssociateDocuments(payload);

        window.open(file_url);
      } catch (error) {
        this.showNotification("error", "Error", error);
      }
    },

    async sendDocuments(email) {
      const payload = {
        associate_id: this.associate.id,
        email,
      };

      if (this.sendDocumentId) {
        payload.document_id = this.sendDocumentId;
      }

      this.loadingSendDocument = true;

      try {
        const sendDocument = this.sendDocumentId
          ? this.sendAssociateDocument
          : this.sendAllAssociateDocument;

        await sendDocument(payload);

        this.showNotification(
          "success",
          "Success",
          "Document successfully sent to email"
        );

        this.closeSendDocumentsModal();
      } catch (error) {
        this.showNotification(
          "error",
          "Error",
          error.message || "An error occurred"
        );
      } finally {
        this.loadingSendDocument = false;
      }
    },

    async fetchDocuments() {
      this.loading = true;

      const payload = {
        associate_id: this.associate.id,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };

      try {
        await this.getSignedDocuments(payload);
      } catch (error) {
        this.showNotification(
          "error",
          "Error",
          error.message || "An error occurred"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
