export const SHIFT_STATUS = Object.freeze({
  scheduled: {
    title: "Scheduled",
    text: "Shift has been scheduled.",
    color: "#b4b4b4",
  },
  started: {
    title: "Started",
    text: "Shift is currently in progress.",
    color: "#52bfff",
  },
  missed: {
    title: "Missed",
    text: "Shift has been missed.",
    color: "#ff4646",
  },
  not_signed: {
    title: "Not Signed",
    text: "Shift is not signed.",
    color: "#ffb546",
  },
  cancelled: {
    title: "Cancelled",
    text: "Shift has been cancelled.",
    color: "#6C69FF",
  },
  completed: {
    title: "Completed",
    color: "#34db30",
    text: "Shift has been competed.",
  },
  deleted: {
    title: "Deleted",
    text: "Shift has been deleted.",
    color: "#ff4646",
  },
});

export const SHIFT_PAYMENT_REQUEST_STATUS = Object.freeze({
  unpaid: {
    title: "Unpaid",
    color: "#ff4646",
    text: "Payment for the shift is pending.",
  },
  auth_issues: {
    title: "Auth Issues",
    color: "#ffb546",
    text: "There are authorization issues with the shift.",
  },
  not_paid: {
    title: "Ready To Pay",
    color: "#34db30",
    text: "Shift has not been paid.",
  },
  draft: {
    title: "Draft Payment",
    color: "#21E29D",
    text: "Payment request is in draft status.",
  },
  paid: {
    title: "Paid",
    color: "#b4b4b4",
    text: "Shift has been paid.",
  },
  in_progress: {
    title: "Payment In Progress",
    color: "#52bfff",
    text: "Payment processing is in progress.",
  },
  partially_paid: {
    title: "Partially Paid",
    color: "#b4b4b4",
    text: "Shift has been partially paid.",
  },
  cancelled: {
    title: "Payment Cancelled",
    color: "#ffb546",
    text: "Payment was cancelled.",
  },
});

export const SHIFT_PAYMENT_STATUS = Object.freeze({
  paid: {
    title: "Paid",
    color: "#b4b4b4",
  },
  accepted: {
    title: "Accepted",
    color: "#52bfff",
  },
  not_paid: {
    title: "Not Paid",
    color: "#34db30",
  },
  cancelled: {
    title: "Cancelled",
    color: "#ffb546",
  },
  deleted: {
    title: "Deleted",
    color: "#ff4646",
  },
  approved: {
    title: "Approved",
    color: "#34db30",
  },
  denied: {
    title: "Denied",
    color: "#ff4646",
  },
});

export const AUTH_STATUSES = Object.freeze({
  early_start_authorization: {
    title: "Early Start",
    color: "#3498db",
    text: "Authorization requested in case the person has started shift early than the time in the schedule",
    type: "payroll",
  },
  late_end_authorization: {
    title: "Late End",
    color: "#d35400",
    text: "Authorization requested in case the person has completed shift later than the time in the schedule",
    type: "payroll",
  },
  overtime_authorization: {
    title: "Unscheduled Overtime",
    color: "#7f8c8d",
    text: "Authorization requested in case of associate overtime",
    type: "payroll",
  },
  no_lunch_authorization: {
    title: "No Meal Break",
    color: "#1abc9c",
    text: "Authorization requested in case the person did not take meal break, although he should have done so",
    type: "payroll",
  },
  no_second_lunch_authorization: {
    title: "No 2nd Meal Break",
    color: "#e67e22",
    text: "Authorization requested in case the person did not take second meal break, although he should have done so",
    type: "payroll",
  },
  unscheduled_shift_authorization: {
    title: "Unscheduled Shift",
    text: "Authorization requested when a shift is worked without pre-scheduling.",
    type: "payroll",
  },
  attachments_missing_authorization: {
    title: "Missed Attachments",
    text: "Authorization requested when required files are not attached.",
    type: "payroll",
  },
  signed_actual_time_discrepancy: {
    title: "Time Mismatch",
    color: "#d35400",
    text: "Authorization requested in case the person has signed shift with an actual time discrepancy",
    type: "payroll",
  },
  no_actual_time_authorization: {
    title: "No Tracked Time",
    color: "#c0392b",
    text: "Authorization requested in case the persons shift has not clock in/out times",
    type: "disciplinary",
  },
  late_start_authorization: {
    title: "Late Start",
    color: "#9b59b6",
    text: "Authorization requested in case the person has started shift later than the time in the schedule",
    type: "disciplinary",
  },
  early_lunch_end_authorization: {
    title: "Short Meal Break",
    color: "#f1c40f",
    text: "Authorization requested in case the person has finished meal break earlier than the meal break duration has passed",
    type: "disciplinary",
  },
  early_second_lunch_end_authorization: {
    title: "Short 2nd Meal Break",
    color: "#e74c3c",
    text: "Authorization requested in case the person has finished second meal break earlier than the meal break duration has passed",
    type: "disciplinary",
  },
  early_end_authorization: {
    title: "Early End",
    color: "#95a5a6",
    text: "Authorization requested in case the person has completed shift early than the time in the schedule",
    type: "disciplinary",
  },
  first_lunch_period_authorization: {
    title: "First Meal Break Period",
    color: "#f1c40f",
    text: "Authorization requested in case the person has worked without break more than available work duration without break",
    type: "disciplinary",
  },
  no_rest_break_authorization: {
    title: "No Rest Breaks",
    color: "#d35400",
    text: "Authorization requested in case the person did not take rest break, although he should have done so",
    type: "disciplinary",
  },
  absence_on_shift_authorization: {
    title: "Absense",
    color: "#8e44ad",
    text: "Authorization is created when an associate is absent for a valid reason",
    type: "disciplinary",
  },
  outside_work_area_authorization: {
    title: "Outside Work Area",
    color: "#34495e",
    text: "Authorization requested in case the person has done action outside the work area specified in the project",
    type: "disciplinary",
  },
  associate_not_recognized_authorization: {
    title: "Associate Not Recognized",
    text: "Authorization requested in case the person face has not detected in photo of action in shift",
    type: "disciplinary",
  },
  uncompleted_required_tasks_authorization: {
    title: "Uncompleted Tasks",
    text: "Authorization requested when critical tasks remain incomplete.",
    type: "disciplinary",
  },
});

export const AUTH_PROCESS_STATUSES = Object.freeze({
  approved: "Approved",
  denied: "Denied",
  unreviewed: "Unreviewed",
});

export const SHIFT_ORIGIN_COLORS = Object.freeze({
  "Time Tracker": "#7432FF",
  Scheduler: "#52BFFF",
  Marketplace: "#FF4646",
});

export const SHIFT_FORM_INITIAL_STATE = {
  duration: 0,
  work_start: null,
  work_end: null,
  lunch_duration: 0,
  lunch_start: null,
  lunch_end: null,
  second_lunch_duration: 0,
  second_lunch_start: null,
  second_lunch_end: null,
  actual_lunch_duration: 0,
  actual_lunch_end: null,
  actual_lunch_start: null,
  actual_second_lunch_duration: 0,
  actual_second_lunch_end: null,
  actual_second_lunch_start: null,
  actual_duration: 0,
  actual_work_end: null,
  actual_work_start: null,
  modified_by_admin: true,
  lunch_waived: false,
};

export const INITIAL_STATE = {
  shiftAuthorizations: [],
  shifts: {},
  shift: {},
  shiftForm: { ...SHIFT_FORM_INITIAL_STATE },
  shiftPayCodes: [],
  shiftCalculations: [],
  shiftPayments: [],
  shiftActions: [],
};
