export const PAYMENT_STATUS = Object.freeze({
  CREATED: {
    title: "Created",
    color: "#b4b4b4",
  },
  PROCESSING: {
    title: "Processing",
    color: "#b4b4b4",
  },
  CONFIRMED: {
    title: "Confirmed",
    color: "#b4b4b4",
  },
  ACCEPTED: {
    title: "Awaiting Shift Approval",
    color: "#ffb546",
  },
  PENDING: {
    title: "Sending Money",
    color: "#52bfff",
  },
  COMPLETED: {
    title: "Paid",
    color: "#34db30",
  },
  CANCELLED: {
    title: "Cancelled",
    color: "#ff4646",
  },
  SHIFTS_APPROVED: {
    title: "Shifts Approved",
    color: "#52bfff",
  },
});

export const PAYMENT_METHOD = Object.freeze({
  hourly: "Hourly",
  salary: "Salary",
});

export const PAYMENT_WORK_STATUS = Object.freeze({
  full_time: "Full Time",
  part_time: "Part Time",
  on_call: "On Call",
  terminated: "Terminated",
});
