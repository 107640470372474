import { render, staticRenderFns } from "./create-user-with-associate.vue?vue&type=template&id=60e3c745&scoped=true&"
import script from "./create-user-with-associate.vue?vue&type=script&lang=js&"
export * from "./create-user-with-associate.vue?vue&type=script&lang=js&"
import style0 from "./create-user-with-associate.vue?vue&type=style&index=0&id=60e3c745&prod&scoped=true&lang=css&"
import style1 from "../assets/css/form-styles.css?vue&type=style&index=1&id=60e3c745&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60e3c745",
  null
  
)

export default component.exports