<template>
  <a-modal
    title="Shift Sign"
    :visible="visible"
    @cancel="closeModal"
    :destroy-on-close="true"
  >
    <div
      v-if="loadingShift || !shift"
      class="loading-spin"
      style="height: 650px; background-color: white"
    >
      <a-spin />
    </div>
    <div v-else>
      <template v-if="shift.version === 0">
        <p class="font-bold">
          Associate hasn’t signed the shift even once, so it doesn’t contain any
          Signed Times. If you sign it, the time will be recorded based on the
          Actual and Scheduled sections.
        </p>
        <p class="font-bold">
          After signing, you’ll be able to adjust the shift times to make sure
          everything is okay.
        </p></template
      >
      <div class="pb-4">
        <a-tabs default-active-key="signed" size="large">
          <a-tab-pane key="signed" tab="Signed">
            <shift-time :times="signedTime" />
          </a-tab-pane>
          <a-tab-pane key="actual" tab="Actual">
            <shift-time :times="actualTime" />
          </a-tab-pane>
          <a-tab-pane key="scheduled" tab="Scheduled">
            <shift-time :times="scheduledTime" />
          </a-tab-pane>
        </a-tabs>
      </div>
      <signature
        checkbox-label="I agree that all data is true and correct"
        :associate="signatureAssociate"
        :available-signature="adminAssociateSignature"
        ref="signatureRef"
        @checked="setSignatureCheckboxValue"
        @save-signature="setSignatureId"
      />
    </div>

    <template slot="footer">
      <div class="flex justify-content-end">
        <a-button
          type="primary"
          :disabled="!checked"
          :loading="loadingSave"
          @click="openConfirmModal"
        >
          Sign
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { Button, Card, Modal, notification, Spin, Tabs } from "ant-design-vue";
import shiftsHelper from "@/helpers/shifts";
import { createNamespacedHelpers } from "vuex";
import util from "@/util";
import Signature from "@/components/signature.vue";
import ShiftTime from "./shift-time.vue";

const { mapState: shiftState, mapActions: shiftActions } =
  createNamespacedHelpers("shifts");

export default {
  components: {
    "a-modal": Modal,
    "a-button": Button,
    "a-spin": Spin,
    "a-card": Card,
    "a-tabs": Tabs,
    "a-tab-pane": Tabs.TabPane,
    signature: Signature,
    "shift-time": ShiftTime,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    shift_id: {
      type: String,
      default: undefined,
    },
    edit: {
      type: Function,
    },
  },
  mixins: [shiftsHelper],
  data() {
    return {
      loadingShift: false,
      loadingSave: false,

      signature_id: undefined,
      checked: false,
    };
  },
  watch: {
    shift_id: {
      handler(newVal) {
        if (newVal) this.fetchShift();
      },
      immediate: true,
    },
    visible(newVal) {
      if (!this.shift && newVal) this.fetchShift();
    },
  },
  computed: {
    ...shiftState({
      shift: (state) => state.shift.shift,
    }),

    scheduledTime() {
      return this.generateTimeData("scheduled_");
    },

    actualTime() {
      return this.generateTimeData("actual_");
    },

    signedTime() {
      return this.generateTimeData("");
    },

    signatureAssociate() {
      return {
        name: util.combineAssociateName(
          this.$store.state.applicationState.associate.first_name,
          this.$store.state.applicationState.associate.last_name
        ),
        email: this.$store.state.applicationState.associate.email,
        phone: this.$store.state.applicationState.associate.phone,
      };
    },
    adminAssociateSignature() {
      return this.$store.state.applicationState.associate.signature;
    },
  },
  methods: {
    ...shiftActions(["getShiftWithAuth", "postSignShift", "updateShift"]),

    setSignatureCheckboxValue(val) {
      this.checked = val;
    },

    async setSignatureId(signatureId) {
      this.signature_id = signatureId;

      this.loadingSave = true;

      try {
        const isVersionZero = this.shift.version === 0;
        const payload = isVersionZero
          ? {
              shift: {
                ...this.shift,
                work_start:
                  this.shift.work_start ??
                  this.shift.actual_work_start ??
                  this.shift.scheduled_start,
                work_end:
                  this.shift.work_end ??
                  this.shift.actual_work_end ??
                  this.shift.scheduled_end,
                lunch_start:
                  this.shift.lunch_start ?? this.shift.actual_lunch_start,
                lunch_end: this.shift.lunch_end ?? this.shift.actual_lunch_end,
                second_lunch_start:
                  this.shift.second_lunch_start ??
                  this.shift.actual_second_lunch_start,
                second_lunch_end:
                  this.shift.second_lunch_end ??
                  this.shift.actual_second_lunch_end,
                modified_by_admin: true,
                rest_break_checked: true,
                signature: { id: this.signature_id },
              },
            }
          : {
              shift_id: this.shift.id,
              version_id: this.shift.version,
              signature_id: this.signature_id,
            };

        const requestMethod = isVersionZero
          ? this.updateShift
          : this.postSignShift;

        await requestMethod(payload);

        this.showNotification(
          "success",
          "Success",
          "The shift has been signed"
        );

        this.$emit("edit");
        this.closeModal();
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingSave = false;
      }
    },

    closeModal() {
      this.$emit("update:visible", false);
    },

    generateTimeData(prefix) {
      return {
        scheduled_start: this.shift.scheduled_start,
        scheduled_end: this.shift.scheduled_end,
        start:
          prefix === "scheduled_"
            ? this.shift[`${prefix}start`]
            : this.shift[`${prefix}work_start`],
        end:
          prefix === "scheduled_"
            ? this.shift[`${prefix}end`]
            : this.shift[`${prefix}work_end`],
        lunch_start: this.shift[`${prefix}lunch_start`] || null,
        lunch_end: this.shift[`${prefix}lunch_end`] || null,
        second_lunch_start: this.shift[`${prefix}second_lunch_start`] || null,
        second_lunch_end: this.shift[`${prefix}second_lunch_end`] || null,
      };
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    async fetchShift() {
      try {
        this.loadingShift = true;

        const payload = {
          shift_id: this.shift_id,
        };

        await this.getShiftWithAuth(payload);
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingShift = false;
      }
    },

    openConfirmModal() {
      if (this.shift.payment_type === "Employee")
        Modal.confirm({
          title: "Are you sure?",
          content: `Are you sure you want to sign this shift on behalf of the EMPLOYEE? This could lead to legal issues down the line. It’s best to only do this in rare, exceptional cases.`,

          onOk: () => {
            this.signShift();
          },
          onCancel() {},
        });
      else {
        this.signShift();
      }
    },

    async signShift() {
      await this.$refs.signatureRef.saveSignature();
    },
  },
  mounted() {
    if (this.shift_id) this.fetchShift();
  },
};
</script>

<style scoped>
.ant-card {
  height: fit-content;
}

.ant-tabs {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

::v-deep .ant-tabs-bar {
  margin: 0;
}
</style>
