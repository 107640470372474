<template>
  <div>
    <h3>Activity</h3>
    <div v-if="loadingAssociate" class="loading-spin">
      <a-spin />
    </div>
    <template v-else>
      <div class="flex flex-column gap-4">
        <a-descriptions :column="1" size="small" class="col-fixed p-0">
          <a-descriptions-item label="Status">
            <a-tag class="m-0" :color="getAssociateStatus.color">{{
              getAssociateStatus.title
            }}</a-tag>
          </a-descriptions-item>

          <template v-if="context">
            <a-descriptions-item
              v-if="context.associate"
              label="Profile Creator"
            >
              <grouped-associate-info
                :first-name="context.associate.first_name"
                :last-name="context.associate.last_name"
                :associate-number="context.associate.associate_number"
              />
            </a-descriptions-item>
            <a-descriptions-item label="Profile Created At">
              {{ formatDateString(context.created_at) }}
            </a-descriptions-item>
            <a-descriptions-item label="Creation Source">
              {{ context.origin }}
            </a-descriptions-item>
          </template>

          <a-descriptions-item label="Hire Date">
            {{
              associate?.hire_date
                ? formatDateString(associate?.hire_date)
                : " - "
            }}
          </a-descriptions-item>

          <a-descriptions-item label="Seniority Date">
            {{
              associate?.seniority_date
                ? formatDateString(associate?.seniority_date)
                : " - "
            }}
          </a-descriptions-item>
          <a-descriptions-item
            v-if="isFutureTermination"
            label="Future Termination"
          >
            {{
              associate?.seniority_date
                ? formatDateString(associate?.seniority_date)
                : " - "
            }}
          </a-descriptions-item>
        </a-descriptions>

        <div>
          <h3>Termination</h3>

          <template v-if="!isTerminated">
            <a-descriptions :column="1" size="small" class="col-fixed p-0">
              <a-descriptions-item label="Author">
                {{ terminationAuthorName }}
              </a-descriptions-item>

              <a-descriptions-item label="Reason">
                {{ associate.termination.reason }}
              </a-descriptions-item>

              <a-descriptions-item label="Termination Date">
                {{ formatDateString(associate.termination.termination_date) }}
              </a-descriptions-item>

              <a-descriptions-item label="Submission Date">
                {{ formatDateString(associate.termination.created_at) }}
              </a-descriptions-item>
            </a-descriptions>

            <div class="col-12 flex gap-2">
              <a-button icon="redo" @click="openActivityConfirm(true)"
                >Rehire</a-button
              ><a-button
                v-if="associate && associate.is_active"
                icon="redo"
                @click="openActivityConfirm(false)"
                >Disable</a-button
              >
            </div>
          </template>

          <div class="grid" v-else>
            <div class="col-12 flex gap-2" v-if="!creatingTermination">
              <a-button
                icon="plus"
                ghost
                @click="switchSetTermination"
                type="danger"
                >Terminate</a-button
              >
            </div>

            <template v-else>
              <b class="col-6"
                >Terminated Date <span class="required-field">*</span></b
              >
              <div class="col-6 text-right">
                <a-date-picker
                  v-model="newTermination.termination_date"
                  placeholder="Enter termination date"
                  class="w-full"
                  :format="visualDateFormat"
                />
              </div>

              <b class="col-6">Reason <span class="required-field">*</span></b>
              <div class="col-6 text-right">
                <a-textarea
                  v-model="newTermination.reason"
                  :autoSize="true"
                  placeholder="Enter termination reason"
                />
              </div>
              <div class="col-10">
                <p class="text-lg font-bold m-0">
                  Terminate ALL Associate Profiles
                </p>
                <p class="m-0">
                  Associate will be terminated and will completely lose access
                  to the company. Do not use this option when closing a specific
                  Associate Profile.
                </p>
              </div>

              <a-checkbox
                class="col-2 text-right"
                :checked="terminateAll"
                @change="handleCheckTerminateAll"
              />

              <div class="col-12 flex gap-2 justify-content-end">
                <a-button @click="cancelTermination">Cancel</a-button>
                <a-button
                  type="danger"
                  @click="openTerminationConfirm"
                  :disabled="saveTerminationDisabled"
                >
                  Terminate
                </a-button>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";
import util from "@/util";
import Util from "@/util";
import {
  Button,
  Checkbox,
  DatePicker,
  Descriptions,
  Input,
  Modal,
  notification,
  Spin,
  Tag,
} from "ant-design-vue";
import moment from "moment-timezone";
import { createNamespacedHelpers } from "vuex";
import { ASSOCIATE_STATUS } from "@/store/modules/profile/constants";

const { mapActions: profileActions, mapState: profileState } =
  createNamespacedHelpers("profile");

const StatusTagColors = Object.freeze({
  GRAY: "#b4b4b4",
  GREEN: "#34db30",
  RED: "#ff4646",
});

export default {
  components: {
    "a-button": Button,
    "a-date-picker": DatePicker,
    "a-textarea": Input.TextArea,
    "a-tag": Tag,
    "a-checkbox": Checkbox,
    "a-spin": Spin,
    "a-descriptions": Descriptions,
    "a-descriptions-item": Descriptions.Item,

    "grouped-associate-info": GroupedAssociateInfo,
  },
  data() {
    return {
      creatingTermination: false,

      newTermination: {
        reason: undefined,
        termination_date: undefined,
      },

      isSwitchChecked: false,
      terminateAll: false,
      loadingAssociate: false,
      context: undefined,
    };
  },
  props: {
    associateId: {
      type: Number,
      default: null,
    },
  },
  watch: {
    associateId: {
      handler(newVal) {
        if (newVal) {
          this.fetchAssociate();
        }
      },
    },
  },
  computed: {
    ...profileState({
      associate: (state) => state.associate,
    }),
    isFutureTermination() {
      return (
        this.associate?.resigned_date &&
        moment()
          .utc()
          .startOf("day")
          .isSameOrBefore(this.associate?.resigned_date)
      );
    },
    isTerminated() {
      return !this.associate?.termination ?? false;
    },
    getAssociateStatus() {
      return (
        ASSOCIATE_STATUS[this.associate.work_status] ?? ASSOCIATE_STATUS.Active
      );
    },
    terminationAuthorName() {
      if (this.isTerminated) {
        return "-";
      }

      const suspensionAuthor = this.associate.termination.author;
      return Util.combineAssociateName(
        suspensionAuthor.first_name,
        suspensionAuthor.last_name
      );
    },
    saveTerminationDisabled() {
      return (
        !this.newTermination.termination_date ||
        !this.newTermination.reason ||
        this.newTermination.reason.length === 0
      );
    },
    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
  },
  methods: {
    ...profileActions([
      "patchAssociateActivate",
      "patchAssociateDeactivate",
      "postAssociateTerminate",
      "postAssociateCancelTerminate",
      "getAssociateById",
      "getCreationContext",
      "reloadAssociate",
    ]),

    formatDateString(dateString) {
      return moment(dateString).utc().format("MM/DD/YYYY");
    },
    showNotification(type, message, description) {
      notification[type]({
        message: message,
        description: description,
      });
    },
    switchSetTermination() {
      this.creatingTermination = !this.creatingTermination;
    },
    resetNewTermination() {
      this.newTermination = {
        termination_date: undefined,
        reason: undefined,
      };
      this.terminateAll = false;
    },
    cancelTermination() {
      this.resetNewTermination();
      this.switchSetTermination();
    },

    handleCheckTerminateAll(e) {
      this.terminateAll = e.target.checked;
    },

    openTerminationConfirm() {
      Modal.confirm({
        title: "Final Paycheck is Ready?",
        content: `Make sure we can issue the final paycheck to the associate within 24 hours of the termination date. If that's not possible, please suspend the associate's work and prepare the paycheck.`,
        onOk: async () => {
          await this.setTermination();
        },
        onCancel() {},
      });
    },

    openActivityConfirm(checked) {
      Modal.confirm({
        title: "Are you sure?",
        content: `You are about to ${
          checked ? "activate" : "disable"
        } an associate. ${
          checked
            ? "If you activate an associate, the termination will be canceled."
            : ""
        } If you are sure - click OK.`,
        onOk: async () => {
          await this.setActivity(checked);
        },
        onCancel() {},
      });
    },

    async setActivity(checked) {
      try {
        if (checked) {
          await this.patchAssociateActivate({
            associate_id: this.associateId,
          });
        } else {
          await this.patchAssociateDeactivate({
            associate_id: this.associateId,
          });
        }

        this.showNotification(
          "success",
          "Activation Set",
          `Associate has been successfully ${
            checked ? "activated" : "disabled"
          }`
        );

        this.fetchAssociate();
        this.reloadAssociate(true);
      } catch (error) {
        this.showNotification("error", "Activation Set Error", error);
      }
    },

    async setTermination() {
      const payload = {
        user_id: this.associate.user_id,
        termination: {
          ...this.newTermination,
          termination_date: moment(this.newTermination.termination_date)
            .utc()
            .startOf("day")
            .toISOString(),
        },
        associate_id: this.terminateAll ? undefined : this.associateId,
      };

      const isFuture = moment(payload.termination.termination_date).isAfter(
        moment().utc().startOf("day")
      );

      try {
        await this.postAssociateTerminate(payload);

        this.showNotification(
          "success",
          "Termination Set",
          isFuture
            ? `Associate will be terminated at ${this.formatDateString(
                moment(payload.termination.termination_date).add(1, "day")
              )}`
            : "Associate is now terminated"
        );

        this.cancelTermination();
        this.reloadAssociate(true);
      } catch (error) {
        this.showNotification("error", "Termination Set Error", error);
      }
    },

    async fetchAssociate() {
      this.loadingAssociate = true;

      const payload = {
        associate_id: this.associateId,
      };

      try {
        const creationContextResponse = await this.getCreationContext(payload);

        const { origin, ...otherData } = creationContextResponse;

        this.context = { ...otherData, origin: util.capitalize(origin) };
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingAssociate = false;
      }
    },
  },

  mounted() {
    if (this.associateId) this.fetchAssociate();
  },
};
</script>

<style scoped>
.ant-calendar-picker {
  margin: 0;
  min-width: unset !important;
}

.ant-descriptions {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

::v-deep .ant-descriptions-item-label {
  font-weight: bold;
  border: none;
  background-color: white;
}

::v-deep .ant-descriptions-row {
  border: none;
}

::v-deep .ant-descriptions-item-content {
  overflow-x: auto;
}

::v-deep .ant-descriptions-view {
  padding: 16px;
}

::v-deep .ant-descriptions-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
