<template>
  <div class="wrapper">
    <a-form-model
      layout="horizontal"
      :model="form"
      :rules="rules"
      ref="formRef"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-form-model-item
        :colon="false"
        label-align="left"
        label="Associate"
        prop="associate_id"
      >
        <search-associates
          :is-associate-id="true"
          :value.sync="form.associate_id"
        />
      </a-form-model-item>

      <a-alert
        message="Select the start and end dates for finding associate's shifts"
        type="info"
        show-icon
      />
      <a-form-model-item
        :colon="false"
        label-align="left"
        label="Date Range"
        prop="dates"
      >
        <a-range-picker v-model="form.dates" separator="-" format="MM/DD/YYYY">
        </a-range-picker>
      </a-form-model-item>
    </a-form-model>

    <a-table
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
          props: { disabled: record.shift.status === 'deleted' },
        }),
      }"
      :rowKey="(record) => record.shift.id"
      :locale="{ emptyText: 'Shifts Not Found' }"
      :loading="loadingShifts"
      size="small"
      :pagination="pagination"
      :columns="columns"
      :data-source="associateShifts"
      :scroll="{ y: 200 }"
      @change="handleTableChange"
    >
      <span slot="date" slot-scope="text, record">
        {{ formatDate(record.shift.scheduled_start) }} -
        {{ formatDate(record.shift.scheduled_end) }}
      </span>
      <span slot="status" slot-scope="text, record">
        <a-tooltip>
          <template slot="title">
            {{ getShiftStatusTagText(record.shift.status) }}
          </template>
          <a-tag :color="getAuthStatusTagColor(record.shift.status)">
            {{ getShiftStatusTagLabel(record.shift.status) }}
          </a-tag>
        </a-tooltip>
      </span>
      <template slot="footer">
        <span v-if="hasSelected">{{
          `Selected ${selectedRowKeys.length} shifts`
        }}</span>
      </template>
    </a-table>

    <a-form-model-item
      :labelCol="{ span: 0 }"
      :colon="false"
      label-align="left"
      prop="shift_ids"
    >
    </a-form-model-item>
  </div>
</template>

<script>
import {
  getAuthStatusTagColor,
  getShiftStatusTagLabel,
  getShiftStatusTagText,
} from "@/const";
import {
  Alert,
  Button,
  DatePicker,
  FormModel,
  Modal,
  notification,
  Select,
  Table,
  Tag,
  Tooltip,
} from "ant-design-vue";
import moment from "moment-timezone";
import { createNamespacedHelpers } from "vuex";
import shiftsHelper from "@/helpers/shifts";
import SearchAssociates from "@/components/search-associates.vue";

const { mapActions: shiftActions } = createNamespacedHelpers("shifts");

const { mapState: searchState } = createNamespacedHelpers("search");

const initialPagination = {
  pageSizeOptions: ["5", "10", "15"],
  showSizeChanger: true,
  pageSize: 5,
  current: 1,
  total: 0,
};

export default {
  components: {
    "a-modal": Modal,
    "a-form-model": FormModel,
    "a-form-model-item": FormModel.Item,
    "a-button": Button,
    "a-alert": Alert,
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-range-picker": DatePicker.RangePicker,
    "a-tag": Tag,
    "a-tooltip": Tooltip,
    "a-table": Table,
    "search-associates": SearchAssociates,
  },
  props: {
    shift_ids: {
      type: Array,
      default() {
        return [];
      },
    },
    initialValues: {
      type: Object,
      default: undefined,
    },
  },
  watch: {
    form: {
      handler(newVal) {
        this.selectedRowKeys = [];

        if (newVal.associate_id && newVal.dates.length > 0) {
          this.fetchShifts();
        }
      },
      deep: true,
      immediate: true,
    },
    initialValues: {
      handler(newVal) {
        if (!newVal) {
          return;
        }

        if (newVal.associateNumber) this.fillAssociate(newVal.associateNumber);

        let start = newVal.start ?? null;
        let end = newVal.end ?? null;

        if (!start && end) {
          start = end;
        } else if (!end && start) {
          end = start;
        }

        this.form.dates = [start, end];
      },
      immediate: true,
      deep: true,
    },
    users: {
      handler(newVal) {
        if (newVal.length > 0 && this.initialValues.associateNumber) {
          this.fillAssociate(this.initialValues.associateNumber);
        }
      },
      immediate: true,
    },
  },
  mixins: [shiftsHelper],
  data() {
    return {
      form: {
        associate_id: undefined,
        dates: [],
      },

      associateShifts: [],
      selectedRowKeys: [],

      pagination: initialPagination,

      rules: {
        associate_id: [
          {
            required: true,
            message: "Please select associate",
            trigger: "change",
          },
        ],
        dates: [
          {
            required: true,
            message: "Please select date range",
            trigger: "change",
            type: "array",
          },
        ],
      },

      loadingAssociated: false,
      loadingShifts: false,

      columns: [
        {
          title: "Shift ID",
          dataIndex: "shift.id",
          align: "center",
        },
        {
          title: "Date",
          scopedSlots: { customRender: "date" },
          align: "center",
        },
        {
          title: "Status",
          scopedSlots: { customRender: "status" },
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...searchState({
      users: (state) => state.users,
    }),

    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  methods: {
    getAuthStatusTagColor,
    getShiftStatusTagLabel,
    getShiftStatusTagText,
    ...shiftActions(["getShifts"]),

    fillAssociate(value) {
      if (!this.users) {
        return undefined;
      }

      const foundAssociate = this.users.find(
        (item) => item.employee_id === value
      );

      this.form.associate_id = foundAssociate
        ? foundAssociate.associate_id
        : undefined;
    },

    formatDate(date) {
      return moment(date).format(this.$store.state.applicationState.dateFormat);
    },

    // work with shifts table

    onSelectChange(selectedRowKeys) {
      this.$emit("update:shift_ids", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },

    handleTableChange(pagination) {
      const isPaginationChanged =
        pagination.current !== this.pagination.current ||
        pagination.pageSize !== this.pagination.pageSize;

      if (!isPaginationChanged) {
        return;
      }

      this.pagination = { ...pagination };

      this.fetchShifts();
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    // work witch shifts (attached)

    async fetchShifts() {
      try {
        const valid = await new Promise((resolve) => {
          this.$refs.formRef.validate(resolve);
        });

        if (!valid) {
          this.showNotification(
            "warning",
            "Validation Error",
            "Please fill in all required fields correctly."
          );

          return;
        }
        this.loadingShifts = true;
        const payload = {
          filter: {
            shift_type: "worked",
            associate_id: this.form.associate_id,
            start_date: moment(this.form.dates[0])
              .startOf("day")
              .utc(true)
              .toISOString(),
            end_date: moment(this.form.dates[1])
              .startOf("day")
              .utc(true)
              .toISOString(),
          },
          page: this.pagination.current,
          size: this.pagination.pageSize,
        };

        const { items, total_count } = await this.getShifts(payload);

        this.associateShifts = items;
        this.pagination.total = total_count;
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingShifts = false;
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.ant-row {
  display: flex;
  margin: 0 !important;
}

.ant-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}

.ant-calendar-picker {
  margin: 0;
  width: 100%;
}
</style>
