<template>
  <a-modal title="Filters" width="600px" :visible="visible" @cancel="cancel">
    <div class="grid">
      <b class="col-4">Associate</b>
      <search-associates
        class="col-8"
        :is-associate-id="true"
        :multiply="true"
        :value.sync="filters.associate_ids"
      />

      <b class="col-4">Reviewer</b>
      <search-associates
        class="col-8"
        :is-associate-id="true"
        :multiply="true"
        :value.sync="filters.reviewer_ids"
      />

      <b class="col-4">Provider</b>
      <search-associates
        class="col-8"
        :is-associate-id="true"
        :multiply="true"
        :value.sync="filters.provider_ids"
      />

      <b class="col-4">Date From</b>
      <a-date-picker
        class="col-8"
        :disabled-date="disabledStartDate"
        format="MM/DD/YYYY"
        placeholder="Start Date"
        v-model="dateFromProxy"
        valueFormat="YYYY-MM-DD"
      />

      <b class="col-4">Date To</b>
      <a-date-picker
        class="col-8"
        :disabled-date="disabledEndDate"
        format="MM/DD/YYYY"
        placeholder="End Date"
        v-model="dateToProxy"
        valueFormat="YYYY-MM-DD"
      />

      <b class="col-4">Payroll Auths</b>
      <a-select
        class="col-8"
        mode="multiple"
        v-model="payrollSelected"
        :allow-clear="true"
        :filter-option="false"
        placeholder="Type"
      >
        <a-select-option
          v-for="type in payrollAuths"
          :key="type.key"
          :value="type.key"
        >
          {{ getAuthType(type.key).title }}
        </a-select-option>
      </a-select>

      <b class="col-4">Disciplinary Auths</b>
      <a-select
        class="col-8"
        mode="multiple"
        v-model="disciplinarySelected"
        :allow-clear="true"
        :filter-option="false"
        placeholder="Type"
      >
        <a-select-option
          v-for="type in disciplinaryAuths"
          :key="type.key"
          :value="type.key"
        >
          {{ getAuthType(type.key).title }}
        </a-select-option>
      </a-select>

      <b class="col-4">Status</b>
      <a-select
        class="col-8"
        v-model="filters.status"
        :allow-clear="true"
        :filter-option="false"
        placeholder="Status"
      >
        <a-select-option
          v-for="status in statuses"
          :key="status.id"
          :value="status.id"
        >
          {{ status.name }}
        </a-select-option>
      </a-select>

      <b class="col-4">Job Classification</b>
      <a-select
        class="col-8"
        v-model="roleProxy"
        :dropdown-match-select-width="false"
        :allow-clear="true"
        placeholder="Job Classification"
      >
        <a-select-option v-for="role in roles" :key="role.id" :value="role.id">
          {{ role }}
        </a-select-option>
      </a-select>

      <template v-if="isShowPaymentType">
        <b class="col-4">Payment Type</b>
        <a-select
          class="col-8"
          v-model="filters.payment_type"
          :allow-clear="true"
          :dropdown-match-select-width="false"
          placeholder="Payment Type"
        >
          <a-select-option
            v-for="type in paymentTypes"
            :key="type.id"
            :value="type.name"
          >
            {{ type.name }}
          </a-select-option>
        </a-select></template
      >

      <div class="flex gap-2 flex-wrap mt-2">
        <a-button
          @click="toggleFilter('assigned_to_me')"
          :type="filters.assigned_to_me ? 'primary' : 'default'"
        >
          Assigned to Me
        </a-button>
      </div>
    </div>
    <template slot="footer">
      <div class="flex justify-content-end">
        <a-button type="primary" @click="apply"> Apply </a-button>
        <a-button type="danger" :disabled="isEmptyFilters" @click="reset">
          Reset
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import {
  Modal,
  Button,
  Select,
  Checkbox,
  Switch,
  DatePicker,
} from "ant-design-vue";
import SearchAssociates from "../../../components/search-associates.vue";
import {
  AUTH_PROCESS_STATUSES,
  AUTH_STATUSES,
} from "@/store/modules/shifts/constants";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";

const { mapGetters: shiftGetters } = createNamespacedHelpers("shifts");

const filtersBaseState = Object.freeze({
  end: undefined,
  start: undefined,
  role: undefined,
  associate_ids: [],
  auth_types: [],
  reviewer_ids: [],
  provider_ids: [],
  payment_type: undefined,
  assigned_to_me: false,
  status: undefined,
});

export default {
  components: {
    "a-modal": Modal,
    "a-button": Button,
    "a-select": Select,
    "a-checkbox": Checkbox,
    "a-select-option": Select.Option,
    "a-switch": Switch,
    "a-date-picker": DatePicker,
    "search-associates": SearchAssociates,
  },
  data() {
    return {
      filters: { ...filtersBaseState },

      disciplinaryAuths: Object.entries(AUTH_STATUSES)
        .map(([key, value]) => ({
          key,
          ...value,
        }))
        .filter((item) => item.type === "disciplinary"),

      payrollAuths: Object.entries(AUTH_STATUSES)
        .map(([key, value]) => ({
          key,
          ...value,
        }))
        .filter((item) => item.type === "payroll"),

      statuses: Object.entries(AUTH_PROCESS_STATUSES).map(([key, value]) => ({
        id: key,
        name: value,
      })),

      roles: this.$store.state.applicationState.projectRoles,
      paymentTypes: this.$store.state.applicationState.paymentTypes,
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    currentFilters: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...shiftGetters(["getAuthType"]),

    isShowPaymentType() {
      return (
        this.$store.state.applicationState.currentPaymentTypeName === "All"
      );
    },

    isDisabledWithResolvedAuths() {
      return (
        (this.filters.reviewer_ids ?? []).length > 0 ||
        (this.filters.provider_ids ?? []).length > 0
      );
    },

    isEmptyFilters() {
      return JSON.stringify(filtersBaseState) === JSON.stringify(this.filters);
    },

    dateFromProxy: {
      get() {
        return this.filters.start ?? null;
      },
      set(value) {
        this.filters.start = value === null ? undefined : value;
      },
    },

    dateToProxy: {
      get() {
        return this.filters.end ?? null;
      },
      set(value) {
        this.filters.end = value === null ? undefined : value;
      },
    },

    roleProxy: {
      get() {
        return this.filters.role ? Number(this.filters.role) : undefined;
      },
      set(value) {
        this.filters.role = value;
      },
    },

    payrollSelected: {
      get() {
        return (this.filters.auth_types ?? []).filter((key) =>
          this.payrollAuths.some((auth) => auth.key === key)
        );
      },
      set(values) {
        this.filters.auth_types = [...values, ...this.disciplinarySelected];
      },
    },

    disciplinarySelected: {
      get() {
        return (this.filters.auth_types ?? []).filter((key) =>
          this.disciplinaryAuths.some((auth) => auth.key === key)
        );
      },
      set(values) {
        this.filters.auth_types = [...values, ...this.payrollSelected];
      },
    },
  },
  watch: {
    currentFilters: {
      handler(newValue) {
        this.filters = { ...newValue };
      },
      immediate: true,
      deep: true,
    },
    visible: {
      handler() {
        this.filters = { ...this.currentFilters };
      },
    },
    "$store.state.applicationState.currentPaymentType": {
      handler() {
        this.filters.payment_type = undefined;
        this.$router.replace({ query: this.filters }).catch(() => {});
      },
      immediate: true,
    },
  },
  methods: {
    reset() {
      if (this.isEmptyFilters) {
        this.cancel();
      } else {
        this.filters = { ...filtersBaseState };
        this.$router.replace({ query: {} }).catch(() => {});
      }
    },

    disabledStartDate(current) {
      const endValue = this.filters.end;
      if (!endValue) {
        return false;
      }

      return current && current.valueOf() > moment(endValue).valueOf();
    },

    disabledEndDate(current) {
      const startValue = this.filters.start;
      if (!startValue) {
        return false;
      }

      return current && current.valueOf() <= moment(startValue).valueOf();
    },

    toggleFilter(type) {
      this.filters[type] = this.filters[type] ? undefined : true;
    },

    cancel() {
      this.$emit("update:visible", false);
    },

    apply() {
      this.cancel();

      this.$router.replace({ query: this.filters }).catch(() => {});
    },
  },

  mounted() {
    const defaultFilters = {
      end: this.$route.query.end ?? moment().format("YYYY-MM-DD"),
      status: this.$route.query.status ?? "unreviewed",
    };

    this.$router
      .replace({
        query: {
          ...this.$route.query,
          ...defaultFilters,
        },
      })
      .catch(() => {});
  },
};
</script>

<style scoped>
.ant-calendar-picker {
  margin: 0;
}
</style>
