var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('div',{staticClass:"loading-spin"},[_c('a-spin',{attrs:{"size":"large"}})],1):_c('div',[_c('a-modal',{attrs:{"title":"You can disable previous associate","footer":null},model:{value:(_vm.openedDisablePreviousAssociateModal),callback:function ($$v) {_vm.openedDisablePreviousAssociateModal=$$v},expression:"openedDisablePreviousAssociateModal"}},[_c('div',{staticClass:"flex flex-column gap-4"},[_c('div',[_c('p',[_vm._v(" You are about to set a new associate with a different pay type or role. ")]),_c('p',[_vm._v(" You have the option to disable the previous associate profile if it is no longer needed. ")])]),_c('div',{staticClass:"flex gap-2 justify-content-end"},[_c('a-button',{on:{"click":function($event){return _vm.cloneAssociate(false)}}},[_vm._v("Leave The Account")]),_c('a-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.cloneAssociate(true)}}},[_vm._v("Disable Previous Associate")])],1)])]),_c('a-form',{attrs:{"layout":"inline","form":_vm.form}},[_c('div',{staticClass:"content-view"},[_c('div',{staticClass:"content-view-block"},[_c('h3',[_vm._v("Personal Info")]),_c('p',[_c('b',[_vm._v("Full Name")]),_c('span',[_vm._v(_vm._s(_vm.associate.first_name)+" "+_vm._s(_vm.associate.last_name))])]),_c('p',[_c('b',[_vm._v("Email")]),_c('span',[_vm._v(_vm._s(_vm.associate.email))])]),_c('p',[_c('b',[_vm._v("Phone Number")]),_c('span',[_vm._v(_vm._s(_vm.associate.phone))])]),_c('p',[_c('b',[_vm._v("Birth Date")]),_c('span',[_vm._v(_vm._s(_vm.birthDate))])]),_c('p',[_c('b',[_vm._v("Address")]),_c('span',[_vm._v(_vm._s(_vm.address))])]),_c('h3',[_vm._v("Company Info")]),_c('p',[_c('b',{staticClass:"associate-number-label"},[_vm._v("Associate Number "),_c('span',{staticClass:"required-field"},[_vm._v("*")])]),_c('a-form-item',{attrs:{"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'associateNumber',
                {
                  rules: [
                    { required: true, message: _vm.associateNumberSuggestionMsg },
                    {
                      len: 6,
                      message: 'The associate number must be 6 characters.',
                    },
                    { validator: _vm.associateNumberValidator },
                  ],
                },
              ]),expression:"[\n                'associateNumber',\n                {\n                  rules: [\n                    { required: true, message: associateNumberSuggestionMsg },\n                    {\n                      len: 6,\n                      message: 'The associate number must be 6 characters.',\n                    },\n                    { validator: associateNumberValidator },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Enter associate number"}})],1)],1),_c('p',[_c('b',[_vm._v("Job Classification "),_c('span',{staticClass:"required-field"},[_vm._v("*")])]),_c('a-form-item',{attrs:{"required":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'projectRole',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please select a job classification!',
                    },
                  ],
                },
              ]),expression:"[\n                'projectRole',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please select a job classification!',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Select job classification"}},_vm._l((_vm.projectRoles),function(role){return _c('a-select-option',{key:role.id,attrs:{"value":role.id}},[_vm._v(_vm._s(role.name)+" ")])}),1)],1)],1),_c('div',{staticClass:"view-button-group"},[_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","loading":_vm.loadingForm,"disabled":_vm.enabledCloneButton},on:{"click":_vm.toggleDisableSuggestionModal}},[_vm._v(" Create New Profile ")])],1)],1)]),_c('div',{staticClass:"content-view-block"},[_c('h3',[_vm._v("Payment Info")]),_c('p',[_c('b',[_vm._v("Payment Type "),_c('span',{staticClass:"required-field"},[_vm._v("*")])]),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'paymentType',
                {
                  rules: [{ required: true }],
                },
              ]),expression:"[\n                'paymentType',\n                {\n                  rules: [{ required: true }],\n                },\n              ]"}],attrs:{"placeholder":"Select PT"},on:{"change":_vm.handlePaymentTypeChange}},_vm._l((_vm.paymentTypes),function(type){return _c('a-select-option',{key:type.id,attrs:{"value":type.id}},[_vm._v(_vm._s(type.name)+" ")])}),1)],1)],1),_c('p',[_c('b',[_vm._v("Payment Status")]),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'paymentStatus',
                {
                  rules: [{ required: false }],
                  initialValue: undefined,
                },
              ]),expression:"[\n                'paymentStatus',\n                {\n                  rules: [{ required: false }],\n                  initialValue: undefined,\n                },\n              ]"}],attrs:{"placeholder":"Select Payment Status"}},_vm._l((_vm.paymentStatuses),function(status){return _c('a-select-option',{key:status.id,attrs:{"value":status.id}},[_vm._v(_vm._s(status.label)+" ")])}),1)],1)],1),(_vm.isEmployeePaymentTypeSelected)?[_c('p',[_c('b',[_vm._v("Payment Method")]),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'paymentMethod',
                  {
                    rules: [{ required: false }],
                  },
                ]),expression:"[\n                  'paymentMethod',\n                  {\n                    rules: [{ required: false }],\n                  },\n                ]"}],attrs:{"placeholder":"Select PM"}},_vm._l((_vm.paymentMethods),function(method){return _c('a-select-option',{key:method.id,attrs:{"value":method.id}},[_vm._v(_vm._s(method.label)+" ")])}),1)],1)],1),_c('p',[_c('b',[_vm._v("Hourly Rate, $")]),_c('a-form-item',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'hourly_rate',
                  { rules: [{ required: false }], initialValue: undefined },
                ]),expression:"[\n                  'hourly_rate',\n                  { rules: [{ required: false }], initialValue: undefined },\n                ]"}],attrs:{"placeholder":"\n                  Hourly Rate\n                ","min":0}})],1)],1)]:_vm._e(),(_vm.isContractorPaymentTypeSelected)?[_c('p',[_c('b',[_vm._v("Agency"),_c('span',{staticClass:"required-field"},[_vm._v("*")])]),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'legalEntity',
                  {
                    rules: [
                      {
                        required: _vm.isContractorPaymentTypeSelected,
                        message: 'Select an legal entity',
                      },
                    ],
                  },
                ]),expression:"[\n                  'legalEntity',\n                  {\n                    rules: [\n                      {\n                        required: isContractorPaymentTypeSelected,\n                        message: 'Select an legal entity',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Please select agency"}},_vm._l((_vm.agencies),function(agency){return _c('a-select-option',{key:agency.id,attrs:{"value":agency.id}},[_vm._v(" "+_vm._s(agency.name)+" ")])}),1)],1)],1),_c('p',[_c('a-form-item',[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'is_legal_entity_admin',
                  { rules: [{ required: false }], initialValue: false },
                ]),expression:"[\n                  'is_legal_entity_admin',\n                  { rules: [{ required: false }], initialValue: false },\n                ]"}]},[_c('a-radio-button',{attrs:{"value":true}},[_vm._v(" Admin ")]),_c('a-radio-button',{attrs:{"value":false}},[_vm._v(" Associate ")])],1)],1)],1),_c('p',[_c('b',[_vm._v("Hourly Rate, $")]),_c('a-form-item',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'hourly_rate',
                  { rules: [{ required: false }], initialValue: 0 },
                ]),expression:"[\n                  'hourly_rate',\n                  { rules: [{ required: false }], initialValue: 0 },\n                ]"}],attrs:{"placeholder":"Enter rate","min":0}})],1)],1)]:_vm._e(),_c('p',[_c('b',[_vm._v("Allow Instant Payment")]),_c('span',[_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'allowInstantPayment',
                  {
                    rules: [
                      {
                        required: false,
                        type: 'boolean',
                        initialValue: true,
                      },
                    ],
                  },
                ]),expression:"[\n                  'allowInstantPayment',\n                  {\n                    rules: [\n                      {\n                        required: false,\n                        type: 'boolean',\n                        initialValue: true,\n                      },\n                    ],\n                  },\n                ]"}]})],1)],1)])],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }