<template>
  <div>
    <div class="create-user-with-associate-controls">
      <h3>New User With Associate</h3>
    </div>
    <a-form layout="inline" :form="form" @submit.prevent="handleSubmit">
      <div class="content-view">
        <div class="content-view-block">
          <div>
            <h3>Personal Information</h3>

            <p>
              <b>First Name <span class="required-field">*</span></b>
              <a-form-item required>
                <a-input
                  v-decorator="[
                    'firstName',
                    {
                      rules: [
                        {
                          required: true,
                          message: firstNameSuggestionMsg,
                          whitespace: true,
                        },
                        { pattern: namePattern, message: nameErrorMsg },
                      ],
                    },
                  ]"
                  placeholder="Enter first name"
                  autocomplete="new-first-name"
                  @input="handleCapitalize('firstName')"
                />
              </a-form-item>
            </p>
            <p>
              <b>Last Name <span class="required-field">*</span></b>
              <a-form-item required>
                <a-input
                  v-decorator="[
                    'lastName',
                    {
                      rules: [
                        {
                          required: true,
                          message: lastNameSuggestionMsg,
                          whitespace: true,
                        },
                        { pattern: namePattern, message: nameErrorMsg },
                      ],
                    },
                  ]"
                  placeholder="Enter last name"
                  autocomplete="new-last-name"
                  @input="handleCapitalize('lastName')"
                />
              </a-form-item>
            </p>
            <p>
              <b>Email <span class="required-field">*</span></b>
              <a-form-item required>
                <a-input
                  v-decorator="[
                    'email',
                    {
                      rules: [
                        { required: true, message: emailSuggestionMsg },
                        { type: 'email', message: emailErrorMsg },
                      ],
                    },
                  ]"
                  placeholder="Enter email"
                  autocomplete="new-email"
                />
              </a-form-item>
            </p>
            <p>
              <b>Phone <span class="required-field">*</span></b>
              <a-form-item required>
                <a-input
                  v-decorator="[
                    'phone',
                    {
                      rules: [
                        { required: true, message: phoneSuggestionMsg },
                        { pattern: /^\+\d{10,15}$/, message: phoneErrorMsg },
                      ],
                    },
                  ]"
                  placeholder="Enter phone"
                  autocomplete="new-phone"
                />
              </a-form-item>
            </p>
            <p>
              <b>Password <span class="required-field">*</span></b>
              <a-form-item required>
                <a-input-password
                  class="password-input"
                  v-decorator="[
                    'password',
                    {
                      rules: [
                        { required: true, message: passwordSuggestionMsg },
                        { min: 6, message: passwordErrorMsg },
                      ],
                    },
                  ]"
                  placeholder="Enter password"
                  autocomplete="new-password"
                />
              </a-form-item>
            </p>
            <p>
              <b class="associate-number-label"
                >Associate Number <span class="required-field">*</span></b
              >
              <a-form-item required>
                <a-input
                  v-decorator="[
                    'associateNumber',
                    {
                      rules: [
                        {
                          required: true,
                          message: associateNumberSuggestionMsg,
                        },
                        {
                          pattern: associateNumberPattern,
                          message: associateNumberErrorMsg,
                        },
                      ],
                    },
                  ]"
                  placeholder="Enter associate number"
                  autocomplete="new-associate-number"
                  @input="handleAssociateNumberChange"
                />
              </a-form-item>
            </p>
            <p>
              <b>SSN<span class="required-field">*</span></b>
              <a-form-item>
                <a-input
                  v-decorator="[
                    'socialSecurityNumber',
                    {
                      rules: [
                        { required: true, message: 'SSN is required' },
                        { pattern: /^\d{9}$/, message: ssnErrorMsg },
                      ],
                    },
                  ]"
                  placeholder="Enter SSN"
                />
              </a-form-item>
            </p>
            <p>
              <b>Birth Date</b>
              <a-form-item>
                <a-date-picker
                  v-decorator="['birthDate', { rules: [{ required: false }] }]"
                  placeholder="Select birth date"
                  :format="visualDateFormat"
                  :disabled-date="disabledBirthDate"
                  @openChange="handleBirthDatePickerOpenChange"
                />
              </a-form-item>
            </p>
            <p>
              <b>Supervisor <span class="required-field">*</span></b>
              <a-form-item required>
                <a-select
                  v-decorator="[
                    'associateSupervisor',
                    { rules: [{ required: true, message: 'Please select a supervisor!' }] }
                  ]"
                  show-search
                  placeholder="Enter supervisor name"
                  allow-clear
                  :loading="searchingSupervisors"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="fetchSupervisors"
                >
                  <a-select-option
                    v-for="user in foundSupervisors"
                    :key="user.associate_id"
                    :value="user.associate_id"
                  >
                    {{ `[${user.associate_number}] ${user.first_name} ${user.last_name}` }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </p>
            <p>
              <b>License <span class="required-field" v-if="!isLicenseRequired">*</span></b>
              <a-form-item :required="!isLicenseRequired">
                <div class="license-fields-container">
                  <a-input
                    v-decorator="['license.number']"
                    placeholder="License number"
                    :disabled="isLicenseRequired"
                  />
                  <a-date-picker
                    v-decorator="['license.expiration_date']"
                    placeholder="Expiration date"
                    format="YYYY-MM-DD"
                    :disabled="isLicenseRequired"
                  />
                </div>
                <a-checkbox v-model="isLicenseRequired" class="license-checkbox">
                  License is not required
                </a-checkbox>
              </a-form-item>
            </p>
          </div>
          <div>
            <h3>Project Information</h3>
            <p>
              <b>Job Classification <span class="required-field">*</span></b>
              <a-form-item required>
                <a-select
                  v-decorator="[
                    'projectRole',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please select a job classification!',
                        },
                      ],
                      initialValue: roleDefaultValue,
                    },
                  ]"
                  placeholder="Select job classification"
                >
                  <a-select-option
                    v-for="role in projectRoles"
                    :key="role.id"
                    :value="role.id"
                    >{{ role.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </p>
            <p>
              <b>Schedule Type <span class="required-field">*</span></b>
              <a-form-item required>
                <a-select
                  v-decorator="[
                    'scheduleType',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please select a schedule type!',
                        },
                      ],
                      initialValue: scheduleTypeDefaultValue,
                    },
                  ]"
                  placeholder="Select ST"
                >
                  <a-select-option
                    v-for="type in scheduleTypes"
                    :key="type.name"
                    :value="type.name"
                    >{{ type.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </p>
          </div>
          <div class="view-button-group">
            <a-form-item>
              <a-button type="danger" @click="handleReset" class="clear-button">
                Clear
              </a-button>
              <a-button type="primary" html-type="submit" :loading="loadingForm">
                Register
              </a-button>
            </a-form-item>
          </div>
        </div>
        <div class="content-view-block">
          <div>
            <h3>Address Information</h3>
            <p>
              <b>Address</b>
              <a-form-item>
                <a-input
                  v-decorator="['address', { rules: [{ required: false }] }]"
                  placeholder="Enter address"
                />
              </a-form-item>
            </p>
            <p>
              <b>City</b>
              <a-form-item>
                <a-input
                  v-decorator="['city', { rules: [{ required: false }] }]"
                  placeholder="Enter city"
                />
              </a-form-item>
            </p>
            <p v-if="showStateField">
              <b>State</b>
              <a-form-item>
                <a-select
                  v-decorator="[
                    'state',
                    { rules: [{ required: false }], initialValue: defaultState },
                  ]"
                  placeholder="Enter state"
                >
                  <a-select-option
                    v-for="state in statesList"
                    :key="state.code"
                    :value="state.code"
                  >
                    {{ state.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </p>
            <p>
              <b>Country</b>
              <a-form-item>
                <a-select
                  v-decorator="[
                    'country',
                    { rules: [{ required: false }], initialValue: defaultCountry },
                  ]"
                  placeholder="Enter country"
                  @change="handleCountryStateChange"
                >
                  <a-select-option
                    v-for="country in countriesList"
                    :key="country.code"
                    :value="country.code"
                  >
                    {{ country.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </p>
            <p>
              <b>Zip</b>
              <a-form-item>
                <a-input
                  v-decorator="['Zipcode', { rules: [{ required: false, min: 5 }]}]"
                  placeholder="Enter zip"
                />
              </a-form-item>
            </p>
          </div>
          <div>
            <h3>Payment Information</h3>
            <p>
              <b>Payment Type</b>
              <a-form-item>
                <a-select
                  v-decorator="[
                    'paymentType',
                    {
                      rules: [{ required: false }],
                      initialValue: paymentTypeDefaultValue,
                    },
                  ]"
                  placeholder="Select PT"
                  @change="handlePaymentTypeChange"
                >
                  <a-select-option
                    v-for="type in paymentTypes"
                    :key="type.id"
                    :value="type.id"
                    >{{ type.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </p>
            <p>
              <b>Payment Status</b>
              <a-form-item>
                <a-select
                  v-decorator="[
                    'paymentStatus',
                    {
                      rules: [{ required: false }],
                      initialValue: paymentStatusDefaultValue,
                    },
                  ]"
                  placeholder="Select Payment Status"
                >
                  <a-select-option
                    v-for="status in paymentStatuses"
                    :key="status.id"
                    :value="status.id"
                    >{{ status.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </p>
            <p
              v-if="isEmployeePaymentTypeSelected && isFullTimePaymentStatus()"
            >
              <b>Payment Method</b>
              <a-form-item>
                <a-select
                  v-decorator="[
                    'paymentMethod',
                    {
                      rules: [{ required: false }],
                      initialValue: paymentMethodDefaultValue,
                    },
                  ]"
                  placeholder="Select PM"
                >
                  <a-select-option
                    v-for="method in paymentMethods"
                    :key="method.id"
                    :value="method.id"
                    >{{ method.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </p>
            <p v-if="isEmployeePaymentTypeSelected">
              <b>Rate, $</b>
              <a-form-item>
                <a-input-number
                  v-decorator="[
                    'hourly_rate',
                    { rules: [{ required: false }], initialValue: undefined },
                  ]"
                  placeholder="Enter rate"
                  :min="0"
                />
              </a-form-item>
            </p>
            <p v-if="isContractorPaymentTypeSelected">
              <b>Agency <span class="required-field">*</span></b>
              <a-form-item
                required
                :rules="[{ required: true, message: agencySuggestionMsg }]"
              >
                <a-select
                  v-decorator="[
                    'selectedAgency',
                    {
                      rules: [
                        {
                          required: true,
                          message: agencySuggestionMsg,
                        },
                      ],
                    },
                  ]"
                  placeholder="Select the agency"
                  @change="handleAgencyChange"
                >
                  <a-select-option
                    v-for="agency in agencies"
                    :key="agency.id"
                    :value="agency.id"
                  >
                    [{{ agency.id }}] - {{ agency.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </p>

            <p v-if="isContractorPaymentTypeSelected">
              <b>Hourly Rate, $</b>
              <a-form-item>
                <a-input-number
                  v-decorator="[
                    'hourly_rate',
                    { rules: [{ required: false }], initialValue: 0 },
                  ]"
                  placeholder="Enter rate"
                  :min="0"
                />
              </a-form-item>
            </p>
          </div>
        </div>
      </div>
    </a-form>
  </div>
</template>

<script>
import {
  Checkbox,
  Card,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Select,
  notification,
  Button,
} from "ant-design-vue";
import ConstData from "@/helpers/const-data";
import api from "@/api";
import Util from "@/util";
import moment from "moment-timezone";
import {debounce} from "@/helpers/debounce";

const ScheduleType = Object.freeze({
  FIXED: "fixed",
  SELF_SCHEDULE: "self_schedule",
  FREE: "free",
});

const PaymentType = Object.freeze({
  EMPLOYEE: 3,
  CONTRACTOR: 6,
  VOLUNTEER: 5,
});

const PaymentStatus = Object.freeze({
  FULL_TIME: "full_time",
  PART_TIME: "part_time",
  ON_CALL: "on_call",
  TERMINATED: "terminated",
});

const PaymentMethod = Object.freeze({
  HOURLY: 0,
  SALARY: 1,
});

export default {
  components: {
    "a-checkbox": Checkbox,
    "a-card": Card,
    "a-form": Form,
    "a-form-item": Form.Item,
    "a-input": Input,
    "a-input-password": Input.Password,
    "a-input-number": InputNumber,
    "a-date-picker": DatePicker,
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-button": Button,
  },
  mixins: [api],
  data() {
    return {
      defaultCountry: "US",
      defaultState: "CA",
      showStateField: true,
      form: undefined,
      hourly_rate: undefined,
      legalEntity: undefined,
      scheduleTypes: [
        { name: "fixed", label: "Strict" },
        { name: "self_schedule", label: "Flexible" },
        { name: "free", label: "Free" },
      ],
      paymentTypes: [
        { id: PaymentType.EMPLOYEE, name: "Employee" },
        { id: PaymentType.CONTRACTOR, name: "Contractor" },
        { id: PaymentType.VOLUNTEER, name: "Volunteer" },
      ],
      paymentStatuses: [
        { id: PaymentStatus.FULL_TIME, label: "Full Time" },
        { id: PaymentStatus.PART_TIME, label: "Part Time" },
        { id: PaymentStatus.ON_CALL, label: "On Call" },
        { id: PaymentStatus.TERMINATED, label: "Terminated" },
      ],
      paymentMethods: [
        { id: PaymentMethod.HOURLY, label: "Hourly" },
        { id: PaymentMethod.SALARY, label: "Salary" },
      ],

      isEmployeePaymentTypeSelected: true,
      isContractorPaymentTypeSelected: false,

      paymentTypeDefaultValue: PaymentType.EMPLOYEE,
      paymentStatusDefaultValue: PaymentStatus.FULL_TIME,
      paymentMethodDefaultValue: PaymentMethod.HOURLY,
      scheduleTypeDefaultValue: ScheduleType.SELF_SCHEDULE,
      roleDefaultValue: undefined,

      namePattern: /^[A-Z][a-zA-Z\s-]{1,24}$/,
      associateNumberPattern: /^\d{6}$/,

      firstNameSuggestionMsg: "Please enter your first name",
      lastNameSuggestionMsg: "Please enter your last name",
      emailSuggestionMsg: "Please enter your email",
      phoneSuggestionMsg: "Please enter your phone number",
      passwordSuggestionMsg: "Please enter password",
      agencySuggestionMsg: "Please select the agency",
      associateNumberSuggestionMsg: "Please enter your associate number",

      selectedAgency: undefined,
      selectedAgencyId: undefined,
      isLicenseRequired: false,
      foundSupervisors: [],
      searchingSupervisors: false,
      filter: '',
      searching: false,

      nameErrorMsg:
        "The first name or last name must be between 2 to 25 characters long and must be capitalized. " +
        "Use letters (Latin alphabet), spaces, and hyphens only. ",
      emailErrorMsg: "Incorrect email",
      phoneErrorMsg:
        "Invalid phone number. It should start with a + and contain 10 to 15 digits.",
      passwordErrorMsg: "Password must be longer than 6 characters.",
      associateNumberErrorMsg: "Associate number must be exactly 6 digits.",
      ssnErrorMsg: "Enter the SSN as 9 digits without hyphens",

      labelCol: {},

      statesList: ConstData.usStates,
      countriesList: ConstData.countries,

      loadingForm: false,
    };
  },
  computed: {
    paymentType() {
      return this.form.getFieldValue("paymentType");
    },
    isContractorOrEmployeePaymentTypeSelected() {
      return (
        this.isEmployeePaymentTypeSelected ||
        this.isContractorPaymentTypeSelected
      );
    },
    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
    serverDateFormat() {
      return this.$store.state.applicationState.serverDateFormat;
    },
    projectRoles() {
      return this.$store.state.applicationState.projectRoles;
    },
    agencies() {
      return this.$store.state.applicationState.currentProject.legal_entities;
    },
  },
methods: {
    handleReset() {
      this.form.resetFields();
    },
    handleCountryStateChange(value) {
      if (value === 'US') {
        this.showStateField = true;
        this.defaultState = "CA";
      } else {
        this.showStateField = false;
        this.defaultState = "";
      }
    },
    handleCapitalize(field) {
      const value = this.form.getFieldValue(field);
      const capitalizedValue = Util.capitalize(value);
      this.form.setFieldsValue({
        [field]: capitalizedValue,
      });
    },
    fetchSupervisors: debounce(async function (value) {
      this.searchingSupervisors = true;
      try {
        const response = await this.apiSearchAssociate(value);
        if (!response.data || !Array.isArray(response.data.associate_list)) return;
        this.foundSupervisors = response.data.associate_list;
      } catch (error) {
        console.error('Error fetching supervisors:', error);
      } finally {
        this.searchingSupervisors = false;
      }
    }, 1000),
    handleAgencyChange(value) {
      const agency = this.agencies.find(agency => agency.id === value);
      this.selectedAgency = agency;
    },
    getPaymentTypeLabel(paymentTypeId) {
      switch (paymentTypeId) {
        case PaymentType.EMPLOYEE:
          return "Employee";
        case PaymentType.CONTRACTOR:
          return "Contractor";
        case PaymentType.VOLUNTEER:
          return "Volunteer";
        default:
          return "";
      }
    },
    calculateDefaultDate() {
      return moment().subtract(18, "years").endOf("day");
    },

    isFullTimePaymentStatus() {
      return (
        this.form.getFieldValue("paymentStatus") === undefined ||
        this.form.getFieldValue("paymentStatus") === PaymentStatus.FULL_TIME
      );
    },

    disabledBirthDate(current) {
      const eighteenYearsAgo = moment().subtract(18, "years");
      return current && current > eighteenYearsAgo.endOf("day");
    },

    handleBirthDatePickerOpenChange() {
      if (this.form.getFieldValue("birthDate") == null) {
        this.form.setFieldsValue({
          birthDate: this.calculateDefaultDate(),
        });
      }
    },
    handleAssociateNumberChange() {
      const associateNumberValue = this.form.getFieldValue("associateNumber");

      if (associateNumberValue) {
        this.apiIsAssociateNumberBusy(associateNumberValue).then((response) => {
          if (response.data.is_associate_number_busy) {
            this.form.setFields({
              associateNumber: {
                value: "",
                errors: [
                  new Error(
                    `Associate number ${associateNumberValue} is busy. Set another one`
                  ),
                ],
              },
            });
          }
        });
      }
    },
    handlePaymentTypeChange(value) {
      switch (value) {
        case PaymentType.EMPLOYEE:
          this.isEmployeePaymentTypeSelected = true;
          this.isContractorPaymentTypeSelected = false;
          break;
        case PaymentType.CONTRACTOR:
          this.isEmployeePaymentTypeSelected = false;
          this.isContractorPaymentTypeSelected = true;
          break;
        default:
          this.isEmployeePaymentTypeSelected = false;
          this.isContractorPaymentTypeSelected = false;
      }
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loadingForm = true;
          const userInfo = {
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            password: values.password,
            phone: values.phone,
            is_allow_sms: false,
            photo_id: null,
            signature_file_id: null,
            birth_date: values.birthDate
              ? moment(values.birthDate).format(this.serverDateFormat)
              : null,
            address: {
              address_line1: values.address ?? null,
              city: values.city ?? null,
              state: values.state ? values.state.toLowerCase() : null,
              country: values.country ? values.country.toLowerCase() : null,
              zipcode: values.zipcode ?? null,
            },
          };
          const associateInfo = {
            associate_number: values.associateNumber ?? null,
            project_id: '',
            signed_project_policy_context_id: null,
            is_verified: true,
            role: {
              id: values.projectRole,
            },
            social_security_number: values.socialSecurityNumber ?? null,
            schedule_type: values.scheduleType,
            license: {
              number: values.license?.number ?? null,
              expiration_date: values.license?.expiration_date
                ? moment(values.license.expiration_date).format(this.serverDateFormat)
                : null,
            },
            supervisor: values.associateSupervisor ?? null,
          };
          const paymentInfo = {
            type: this.getPaymentTypeLabel(values.paymentType),
            status: values.paymentStatus,
            method: values.paymentMethod ?? PaymentMethod.HOURLY,
            hourly_rate: values.hourly_rate ? Util.convertMoneyToDb(values.hourly_rate) : 0,
            legal_entity: this.isContractorPaymentTypeSelected
              ? this.selectedAgency
              : null,
          };

          this.apiCreateUserWithAssociate(
            userInfo,
            associateInfo,
            paymentInfo
          )
            .then((response) => {
              const body = response.body;

              if (body.error_code && body.error_code !== "0") {
                this.showIncorrectFormError(response.body.msg);
              } else {
                notification.success({
                  message: "Successfully created",
                  description: `
                  User with the name ${body.associate.first_name} ${body.associate.last_name}
                  and associate number ${body.associate.associate_number} was successfully created`,
                });
                this.$router.push({ name: 'associate-profiles' }).catch(() => {});
                this.form.resetFields();
              }
            })
            .finally(() => {
              this.loadingForm = false;
            });
        } else {
          this.showIncorrectFormError();
        }
      });
    },
    showIncorrectFormError(msg) {
      notification.warning({
        message: "Incorrect form",
        description:
          msg ??
          "Some fields are not filled in or are filled in incorrectly. Please check the fields",
      });
    },
  },
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "create_user_form" });

    const workerRole = this.$store.state.applicationState.projectRoles.find(
      (role) => role.name.toLowerCase() == "worker"
    );

    if (workerRole) {
      this.roleDefaultValue = workerRole.id;
    }
  },
};
</script>

<style scoped>
.clear-button {
  margin-right: 10px;
}

.content-view-block {
  min-width: 400px;
}

.create-user-with-associate-controls {
  padding: 10px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.associate-number-label {
  word-wrap: normal;
}
</style>

<style lang="css" scoped src="../assets/css/form-styles.css" />
