export default {
  data() {
    return {
      tableScrollHeight: 0,

      columns: [
        {
          title: "Associate",
          key: "associate",
          scopedSlots: { customRender: "associate" },
          sorter: true,
        },
        {
          title: "Job classification",
          dataIndex: "role.name",
          key: "role",
          sorter: true,
        },
        {
          title: "Payment Type",
          dataIndex: "payment_info.type",
          sorter: true,
        },
        {
          title: "Pay Status",
          scopedSlots: { customRender: "payStatus" },
          key: "pay_status",
          sorter: true,
        },
        {
          title: "Hourly Rate, $",
          key: "hourly_rate",
          sorter: true,
          align: "center",
          customRender: (_, record) => record.payment_info.hourly_rate / 100,
        },
        {
          title: "Salary for Pay Period, $",
          key: "salary",
          sorter: true,
          align: "center",
          customRender: (_, record) =>
            record.payment_info.method === "salary"
              ? record.payment_info.salary / 100
              : " - ",
        },
        {
          title: "Status",
          key: "status",
          scopedSlots: { customRender: "isActive" },
          align: "center",
        },
        {
          title: "Verified",
          key: "verified",
          scopedSlots: { customRender: "verified" },
          align: "center",
        },
        {
          title: "Schedule Type",
          key: "schedule_type",
          scopedSlots: { customRender: "scheduleType" },
          sorter: true,
        },
        {
          title: "Actions",
          scopedSlots: { customRender: "actions" },
        },
      ],

      pagination: {
        pageSizeOptions: ["10", "50", "100"],
        pageSize: 10,
        current: 1,
        total: 0,
        showSizeChanger: true,
      },
    };
  },
  methods: {
    calculateTableHeight() {
      this.tableScrollHeight = window.innerHeight - 260;
    },
  },
};
