import api from "@/api";
import authUtil from "@/auth.util";
import moment from "moment-timezone";
import Vue from "vue";

export default {
  loadTimeZone({ commit, state }) {
    const timeZone = localStorage.getItem("timeZone");
    if (timeZone) {
      commit("setTimeZone", timeZone);
    }
    moment.tz.setDefault(state.timeZone);
  },
  async loadApplicationState({ commit, state }) {
    if (state.token === null) {
      return;
    }

    this.dispatch("loadTimeZone");

    try {
      await Vue.http
        .post(api.methods.getApiCallAddress() + "tc_get_user_info_state")
        .then((response) => {
          const body = response.body;

          if (!body.error_code || body.error_code != "0") {
            authUtil.handleAuthError(body);
            return;
          }

          const receivedState = body.state;

          const updateStateIfDifferent = (mutation, stateKey, newValue) => {
            if (JSON.stringify(state[stateKey]) !== JSON.stringify(newValue)) {
              JSON.stringify(state);
              commit(mutation, newValue);
            }
          };

          updateStateIfDifferent("setUser", "user", receivedState.user);
          updateStateIfDifferent(
            "setAssociate",
            "associate",
            receivedState.associate
          );
          updateStateIfDifferent(
            "setCurrentProject",
            "currentProject",
            receivedState.current_project
          );
          updateStateIfDifferent(
            "setAllProjects",
            "allProjects",
            receivedState.all_projects
          );
          updateStateIfDifferent("setIsProd", "isProd", receivedState.is_prod);
          updateStateIfDifferent(
            "setProjectLegalEntities",
            "projectLegalEntities",
            receivedState.legal_entities
          );
          updateStateIfDifferent(
            "setCurrentPaymentType",
            "currentPaymentType",
            receivedState.current_payment_type
          );
          updateStateIfDifferent(
            "setCurrentPaymentTypeName",
            "currentPaymentTypeName",
            receivedState.current_payment_type_name
          );

          const shiftAuthTypes = receivedState.shift_auth_types.reduce(
            (obj, type) => {
              obj[type.key] = type.name;
              return obj;
            },
            {}
          );

          updateStateIfDifferent(
            "setShiftAuthTypes",
            "shiftAuthTypes",
            shiftAuthTypes
          );
          updateStateIfDifferent(
            "setExtendedShiftAuthTypes",
            "shiftAuthTypes",
            receivedState.shift_auth_types
          );

          updateStateIfDifferent(
            "setTimeConstants",
            "timeConstants",
            receivedState.time_constants
          );
          updateStateIfDifferent(
            "setProjectRoles",
            "projectRoles",
            receivedState.roles
          );
          updateStateIfDifferent(
            "setProjectDepartments",
            "projectDepartments",
            receivedState.departments
          );
          updateStateIfDifferent(
            "setScheduleTypes",
            "scheduleTypes",
            receivedState.schedule_types
          );
          updateStateIfDifferent(
            "setPaymentTypes",
            "paymentTypes",
            receivedState.payment_types
          );

          localStorage.setItem("applicationState", JSON.stringify(state));
        });
    } catch (exception) {}
  },
};
