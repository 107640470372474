<template>
  <div>
    <div class="grid" v-for="item in availableAuthorizations" :key="item.key">
      <div class="col">
        <p class="text-lg font-bold m-0">
          {{ item.title }}
        </p>
        <p class="role-property-description">
          {{ item.text }}
        </p>
      </div>
      <div class="col-fixed">
        <a-switch
          :disabled="isDisabled"
          :checked="hasAuthorization(item.key)"
          @change="handleSwitchChange(item)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Switch } from "ant-design-vue";
import { AUTH_STATUSES } from "@/store/modules/shifts/constants";

export default {
  components: {
    "a-switch": Switch,
  },
  props: {
    authorizations: {
      type: Array,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      availableAuthorizations: this.getAuthorizations(),
    };
  },
  methods: {
    getAuthorizations() {
      return Object.entries(AUTH_STATUSES).map(([key, value]) => ({
        key,
        ...value,
      }));
    },

    hasAuthorization(key) {
      return this.authorizations.includes(key);
    },

    handleSwitchChange(item) {
      const updatedAuthorizations = this.hasAuthorization(item.key)
        ? this.authorizations.filter((roleItem) => roleItem !== item.key)
        : [...this.authorizations, item.key];

      this.$emit("update:authorizations", updatedAuthorizations);
    },
  },
};
</script>
